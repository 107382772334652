import React from "react";
import MaterialTable from "material-table";
import { useDispatch } from "react-redux";
import { Switch } from '@material-ui/core';
import moment from "moment";
import { doc, setDoc, onSnapshot, collection, query, where, addDoc, getDocs, updateDoc } from "@firebase/firestore"; 
import { db } from "../../../../Redux/firebase";
import { deleteUserByID } from "../../../../Redux/actions/adminUserAction";

const AdminUserTable = ({ users }) => {
  const dispatch = useDispatch();

  const handleSwitchChange = async (event, rowData) => {
    const userId = rowData.id;
    try {
      const updatedData = { enabled: !rowData.enabled };
      const docRef = doc(db, 'adminusers', userId);
      await updateDoc(docRef, updatedData); 
      //update redux store as well
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };


  return (
    <div>
    <MaterialTable
  title="List Of Admin Users"
  columns={[
    { title: "Name", field: "name" },
    { title: "Email", field: "email" },
    {
      title: "Active", 
      field: "enable",
      render: rowData => (
        <Switch
          checked={rowData.enabled}
          onChange={(event) => handleSwitchChange(event, rowData)}
          color="primary"
        />
      ),
    },
    { 
      title: "Role", 
      field: "role",
      render: rowData => {
       
        if (rowData.role === 'SA') {
          return 'Super Admin';
        } else if (rowData.role === 'ADMIN') {
          return 'Administrator';
        } else if (rowData.role === 'CL') {
          return 'Cell Leader';
        } else if (rowData.role === 'DL') {
          return 'Departmental Leader';
        } else if (rowData.role === 'SEC') {
          return 'Secretary';
        } else if (rowData.role === 'MEDIA') {
          return 'Media';
        } else {
          return rowData.role; 
        }
      }
    },
    { title: "Time Created", field: "created_at",
      render: rowData => moment(rowData.created_at).format("DD/MM/YYYY")
     },
  ]}
  data={users}
  actions={[
    {
      icon: "edit",
      tooltip: "Edit Admin User",
      onClick: (event, rowData) => console.log("edit", rowData),
    },
  ]}
  editable={{
    onRowDelete: (oldData) => dispatch(deleteUserByID(oldData.id)),
  }}
/>

    </div>
  );
};

export default AdminUserTable;


