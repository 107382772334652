import { initializeApp } from '@firebase/app';
import { getFirestore, doc, getDoc, collection } from '@firebase/firestore';
import { getStorage } from '@firebase/storage';

import { getAuth } from '@firebase/auth';

// development
// const firebaseConfig = {
//   apiKey: 'AIzaSyBHTowqYB2dkw3QKmrrwrref8qgVztPkdI',
//   authDomain: 'gloryplusintl-acd3f.firebaseapp.com',
//   databaseURL: 'https://gloryplusintl-acd3f.firebaseio.com',
//   projectId: 'gloryplusintl-acd3f',
//   storageBucket: 'gloryplusintl-acd3f.appspot.com',
//   messagingSenderId: '243368275163',
//   appId: '1:243368275163:web:980fb542cdf08e2ed9f0fe',
//   measurementId: 'G-C6V3MT26VB',
// };

// prod
const firebaseConfig = {
  apiKey: "AIzaSyC6z5OW4NCAm1t_xG1VFw9HyckoAWUx1X4",
  authDomain: "gloryplus-prod.firebaseapp.com",
  projectId: "gloryplus-prod",
  storageBucket: "gloryplus-prod.firebasestorage.app",
  messagingSenderId: "920320891004",
  appId: "1:920320891004:web:79aad3f027f4c7deb791f9",
  measurementId: "G-TC7BD5F27L"
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, doc, getDoc, storage };
