import React, { memo } from "react";
import MaterialTable from "material-table";
import { useDispatch } from "react-redux";
import { moveAsSavedSoul } from "../../../../Redux/actions/MemberActions";

const MemberListTable = ({ members, history }) => {
  const dispatch = useDispatch();
  return (
    <div>
      <MaterialTable
        title="All Members"
        columns={[
          { title: "Full Name", field: "name" },
          { title: "Email", field: "email" },
          { title: "Phone", field: "phone" },
          { title: "Gender", field: "gender" },
          { title: "Country", field: "country" },
          { title: "State", field: "state" },
          { title: "Address", field: "address" },
          { title: "Occupation", field: "occupation" },
          { title: "Department", field: "department" },
          { title: "School", field: "courses" },
          { title: "Cell", field: "cell" },
        ]}
        data={members}
        actions={[
          {
            icon: "contacts",
            tooltip: "View Member",
            onClick: (event, rowData) => history.push(`/dashboard/members/${rowData.id}`, { user: rowData }),
          },{
            icon: "track_changes",
            tooltip: "Mark as Saved Soul",
            onClick: (event, rowData) => dispatch(moveAsSavedSoul(rowData)),
          }
        ]}

        options={{
          exportButton: true,
          sorting: true,
        }}
        
      />
    </div>
  );
};

export default memo(MemberListTable);


