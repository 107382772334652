import { 
    FETCH_ATTENDANCE_SUCCESS,
    FETCH_ATTENDANCE_REQUEST,
    FETCH_ATTENDANCE_FAILURE,
    UPDATE_USER_CHURCH_ATTENDANCE,
    UPDATE_USER_CELL_ATTENDANCE
 } from "../actions/action-types";
//here the reducer is going to evaluate any action that has been committed such as fetching and creating posts

const initialState = {
    loading: false,
    error: "",
    initial: [],
    user_cell_records: [],
    user_church_records: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

          case FETCH_ATTENDANCE_REQUEST:
                    return {
                        ...state,
                        loading: true,
                    };
       
        case FETCH_ATTENDANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                initial: action.payload,
                error: "",
            };
        case FETCH_ATTENDANCE_FAILURE:
            return {
                ...state,
                loading: false,
                initial: [],
                error: action.payload,
            };

            case UPDATE_USER_CHURCH_ATTENDANCE:
                return {
                    ...state,
                    loading: false,
                    user_church_records: action.payload,
                    error: "",
                };

            case UPDATE_USER_CELL_ATTENDANCE:
                return {
                    ...state,
                    loading: false,
                    user_cell_records: action.payload,
                    error: "",
                };

            case 'UPDATE_ATTENDANCE':
                // Find the user to update by id and update it
                const updateINitials = state.initial.map(user =>
                  user.id === action.payload.docId
                    ? { ...user, ...action.payload.updatedData }
                    : user
                );
                return {
                  ...state,
                  initial: updateINitials,
                };
        default:
            return state;
    }
};

export default reducer;
