import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import Helmet from 'react-helmet';
import PrivateRoute from './PrivateRoute';
import { Role } from '../_helpers';
import {
  Login,
  ForgotPassword,
  Dashboard,
  Error404Page,
  CreateCourse,
  LiveStream,
  User,
  CourseLists,
  CreateMember,
  CreateEvent,
  CreateLesson,
  EventLists,
  CreateAssignment,
  CreateGallery,
  CreateSermon,
  SermonList,
  CandidateLists,
  CreateCell,
  CreateBranch,
  BranchList,
  CreateDepartment,
  Departments,
  DepartMembers,
  PopulateGallery,
  ViewMembers,
  ViewMember,
  FirstTimers,
  SecondTimers,
  Cells,
  AdminUsers,
  CreateAdminUsers,
  Department,
  SavedSouls
} from '../components/Pages';

import { CellMembers } from '../components/Pages/Cell';

const Routes = () => {
  return (
    <Fragment>
      <Switch>
        <Route exact path="/" component={Login} />

        <PrivateRoute
          exact
          path="/dashboard/"
          component={Dashboard}
          roles={[Role.ADMIN, Role.SA]}
        />

        <PrivateRoute
          exact
          path="/dashboard/admin/users"
          component={AdminUsers}
          roles={[Role.ADMIN, Role.SA]}
        />

        <PrivateRoute
          exact
          path="/dashboard/admin/users/create"
          component={CreateAdminUsers}
          roles={[Role.ADMIN, Role.SA]}
        />
       

        {/* GPA :::::::::::::::::::::::::::::::::::::::::::::::::::::: */}
        <PrivateRoute
          exact
          path="/dashboard/member/create"
          component={CreateMember}
          roles={[Role.ADMIN, Role.SA]}
        />


        <PrivateRoute
          exact
          path="/dashboard/members"
          component={ViewMembers}
          roles={[Role.ADMIN, Role.SA]}
        />


        <PrivateRoute
          exact
          path= "/dashboard/member/first"
          component={FirstTimers}
          roles={[Role.ADMIN, Role.SA]}
        />

        <PrivateRoute
          exact
          path="/dashboard/members/returnees" // second timers
          component={SecondTimers}
          roles={[Role.ADMIN, Role.SA, Role.MVP]}
        />

        <PrivateRoute
          exact
          component={SavedSouls}
          path= "/dashboard/members/savedsouls" 
          roles={[Role.ADMIN, Role.SA, Role.MVP]}
        />


        <PrivateRoute
          exact
          path="/dashboard/members/:id"
          component={ViewMember}
          roles={[Role.SA, Role.ADMIN]}
        />

        <PrivateRoute
          exact
          path="/dashboard/gpa/create"
          component={CreateCourse}
          roles={[Role.SA, Role.ADMIN, Role.MVP]}
        />

        <PrivateRoute
          exact
          path="/dashboard/gpa/view"
          component={CourseLists}
          roles={[Role.SA, Role.ADMIN]}
        />

        <PrivateRoute
          exact
          path="/dashboard/gpa/view/:title/:id"
          component={CreateLesson}
          roles={[Role.SA, Role.ADMIN]}
        />

        <PrivateRoute
          exact
          path="/dashboard/gpa/lesson/create/:id"
          component={CreateLesson}
          roles={[Role.SA, Role.ADMIN]}
        />

        <PrivateRoute
          exact
          path="/dashboard/gpa/assignment/:id"
          component={CreateAssignment}
          roles={[Role.SA, Role.ADMIN]}
        />

        <PrivateRoute
          exact
          path="/dashboard/cell/members"
          component={CellMembers}
          roles={[Role.SA, Role.ADMIN, Role.CL]}
        />

        <PrivateRoute
          exact
          path="/dashboard/gpa/candidates"
          component={CandidateLists}
          roles={[Role.SA, Role.ADMIN]}
        />

        {/* GPA :::::::::::::::::::::::::::::::::::::::::::::::::::::: */}

        {/* MEDIA STARTS :::::::::::::::::::::::::::::::::::::::::::: */}
        <PrivateRoute
          exact
          path="/dashboard/event/create"
          component={CreateEvent}
          roles={[Role.SA, Role.MEDIA, Role.ADMIN]}
        />
        <PrivateRoute
          exact
          path="/dashboard/:id/gallery"
          component={PopulateGallery}
          roles={[Role.SA, Role.MEDIA, Role.ADMIN]}
        />

        <PrivateRoute
          exact
          path="/dashboard/event/view"
          component={EventLists}
          roles={[Role.SA, Role.MEDIA, Role.ADMIN]}
        />

        <PrivateRoute
          exact
          path="/dashboard/gallery/create"
          component={CreateGallery}
          roles={[Role.SA, Role.MEDIA, Role.ADMIN]}
        />

        <PrivateRoute exact path="/dashboard/:id/user" component={User} />

        <PrivateRoute
          exact
          path="/dashboard/sermon/create"
          component={CreateSermon}
          roles={[Role.SA, Role.MEDIA, Role.ADMIN]}
        />

        <PrivateRoute
          path="/dashboard/sermon/view"
          component={SermonList}
          roles={[Role.SA, Role.MEDIA, Role.ADMIN]}
        />

        <PrivateRoute
          exact
          path="/livestream/create"
          component={LiveStream}
          roles={[Role.ADMIN, Role.SA, Role.MEDIA]}
        />
        {/* MEDIA  ENDS :::::::::::::::::::::::::::::::::::::::::::: */}

        <PrivateRoute
          exact
          path="/dashboard/cell/create"
          component={CreateCell}
          roles={[Role.SA, Role.ADMIN, Role.CL]}
        />

        <PrivateRoute
          exact
          path="/dashboard/cell/view"
          component={Cells}
          roles={[Role.SA, Role.ADMIN, Role.CL]}
        />

        {/* MVP :::::::::::::::::::::::::::::::::::::::::::::: */}

        <PrivateRoute
          exact
          path="/dashboard/department/create"
          component={CreateDepartment}
          roles={[Role.SA, Role.DL, Role.ADMIN]}
        />

        <PrivateRoute
          exact
          path="/dashboard/department/list"
          component={Departments}
          roles={[Role.SA, Role.DL]}
        />

        <PrivateRoute
          exact
          path="/dashboard/department/attendance"
          component={DepartMembers}
          roles={[Role.SA, Role.DL, Role.ADMIN]}
        />

        <PrivateRoute
          exact
          path="/dashboard/department/members/:name/:id"
          component={Department}
          roles={[Role.SA, Role.DL, Role.ADMIN]}
        />
        {/* MVP :::::::::::::::::::::::::::::::::::::::::::::: */}

        {/* BRANCHES ROUTE ::::::::::::::::::::::::::::::::::::::::::::::::::: */}
        <PrivateRoute
          exact
          path="/dashboard/branch/create"
          component={CreateBranch}
          roles={[Role.SA, Role.ADMIN]}
        />
        <PrivateRoute
          exact
          path="/dashboard/branch/view"
          component={BranchList}
          roles={[Role.SA, Role.ADMIN]}
        />

        <Route exact path="/recover" component={ForgotPassword} />

        {/* 404 ERROR ROUTE ########################################################*/}
        <Route path="*">
          <Helmet>
            <title>404</title>
          </Helmet>
          <Error404Page />
        </Route>
        {/* 404 ERROR ROUTE #######################################################*/}
      </Switch>
    </Fragment>
  );
};

export default Routes;
