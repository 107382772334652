import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  TextField,
  MenuItem,
  Button,
  Grid,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom"
import { Formik, Form } from "formik";
import { Header, SideBar } from "../../Partials";
import { registerAdminUser } from "../../../Redux/actions/adminUserAction";


const validationSchema = Yup.object().shape({
  name: Yup.string().required("name is required"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("address is required"),
  role: Yup.string().required("role is required"),
});

const CreateAdminUsers = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <ToastContainer />
      <Header />
      <SideBar />
      <div className="page-content">

        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/dashboard/">Dashboard</a></li>
                  <li className="breadcrumb-item"><a href="#">Create Admin User</a></li>
                </ol>
              </nav>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <Button href="/dashboard/members" variant="contained" color="primary">View Admin Users</Button>
                 
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                
                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      password: "",
                      role: "",

                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      setSubmitting(true);
                      try {
                      const data = {
                          name: values.name,
                          password: values.password,
                          email: values.email,
                          role: values.role,
                          created_at: Date.now(),
                          
                        };
                      
                        dispatch(registerAdminUser(data));
                        resetForm();
                        setSubmitting(false);
                        history.push("/dashboard/admin/users");
                       
                        } catch(err) {
                          toast.error(`${err}`, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });
                        };
                    }}
                  >
                    {({
                      values,
                      handleSubmit,
                      isSubmitting,
                      handleChange,
                      touched,
                      errors,
                      handleBlur,
                    }) => (                 
                        <Form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                  {/* Gender Field */}
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      id="name"
                                      label="Name"
                                      name="name"
                                       margin="normal"
                                      onChange={handleChange}
                                      value={values.name}
                                      onBlur={handleBlur}
                                      error={errors.name && touched.name}
                                      helperText={(errors.name && touched.name) && errors.name}
                                      fullWidth
                                      required
                                    />
                                  </Grid>

                                 

                                  {/* Country Field */}
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      label="Select Role"
                                      name="role"
                                      id="role"
                                      select
                                      margin="normal"
                                      value={values.country}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      required
                                      error={errors.role && touched.role}
                                      helperText={(errors.role && touched.role) && errors.role}
                                      fullWidth
                                    >
                                      <MenuItem key={''} value={''}> </MenuItem>
                                      <MenuItem key={"SA"} value={"SA"}>Super Admin</MenuItem>
                                      <MenuItem key={"ADMIN"} value={"ADMIN"}>Admin</MenuItem>
                                      <MenuItem key={"CL"} value={"CL"}>Cell Leader</MenuItem>
                                      <MenuItem key={"DL"} value={"DL"}>Department Leader</MenuItem>
                                      <MenuItem key={"SEC"} value={"SEC"}>Secretary</MenuItem>
                                      <MenuItem key={"MEDIA"} value={"MEDIA"}>Media</MenuItem>
                                      <MenuItem key={"MVP"} value={"MVP"}>MVP</MenuItem>

                                    </TextField>
                                  </Grid>


                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      id="email"
                                      label="Email"
                                      name="email"
                                       margin="normal"
                                      onChange={handleChange}
                                      value={values.email}
                                      onBlur={handleBlur}
                                      error={errors.email && touched.email}
                                      helperText={(errors.email && touched.email) && errors.email}
                                      fullWidth
                                      required
                                    />
                                  </Grid>


                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      id="password"
                                      label="Password"
                                      name="password"
                                       margin="normal"
                                      onChange={handleChange}
                                      value={values.password}
                                      onBlur={handleBlur}
                                      error={errors.password && touched.password}
                                      helperText={(errors.password && touched.password) && errors.password}
                                      fullWidth
                                      required
                                      autoComplete="current-password"
                                    />
                                  </Grid>

                                  <Grid item xs={12}></Grid>

                                  {/* Submit Button */}
                                  <Grid item xs={12}>
                                    <Button type="submit" variant="contained" color="primary" fullWidth>
                                      Submit
                                    </Button>
                                  </Grid>
                                </Grid>


                        </Form>
                      )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
}

export default CreateAdminUsers;
