import { doc, setDoc, onSnapshot, collection, query, where, addDoc, getDocs, deleteDoc } from "@firebase/firestore"; 
import { FETCH_COURSES_SUCCESS, FETCH_COURSES_REQUEST, FETCH_COURSES_FAILURE } from "./action-types";
import { db } from "../firebase";
const API_URL = process.env.REACT_APP_BASEURL;

export const fetchCourseLists = () => {
  return async (dispatch) => {
    dispatch(request());
    try {
      const collectionRef = collection(db, "courses"); 
      const snapshot = await getDocs(collectionRef);
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      dispatch(success(data));
    } catch (error) {
      console.log('error: ', error)
      dispatch(failure(error));
    }
  };
};

export const request = () => {
  return {
    type: FETCH_COURSES_REQUEST,
  };
};

export const success = (courseItems) => {
  return {
    type: FETCH_COURSES_SUCCESS,
    payload: courseItems,
  };
};

export const failure = (error) => {
  return {
    type: FETCH_COURSES_FAILURE,
    payload: error,
  };
};
