import { doc, setDoc, addDoc, collection, query, where, serverTimestamp, getDocs, deleteDoc } from "@firebase/firestore"; 
import { toast } from "react-toastify";
import { db } from "../firebase";
import {
  FETCH_MEMBERS_REQUEST,
  FETCH_MEMBERS_SUCCESS,
  FETCH_MEMBERS_FAILURE,
  FETCH_CHURCH_SUCCESS,
  FETCH_CELL_SUCCESS,
  FETCH_FIRST_TIMERS_SUCCESS,
  FETCH_SECOND_TIMER_SUCCESS,
  FETCH_SAVED_SOULS_SUCCESS
} from "./action-types";

const API_URL = process.env.REACT_APP_BASEURL;

export const fetchMembers = () => {
  return async (dispatch) => {
    dispatch(request());
    const collectionRef = collection(db, "members"); 
    const q = query(collectionRef, where('membership_status', '==', 'Member'));
    const snapshot = await getDocs(q);
    const data = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    dispatch(success(data));
  };
};

export const fetchSavedSouls = () => {
  return async (dispatch) => {
    dispatch(request());
    const usersCollection = collection(db, "savedsouls");
    const snapshot = await getDocs(usersCollection);
    const data = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    dispatch(savedSoulSuccess(data));
  };
};

export const fetchFirstTimers = () => {
  return async (dispatch) => {
    dispatch(request());
    const collectionRef = collection(db, "members"); 
    const q = query(collectionRef, where('membership_status', '==', 'First Timer'));
    const snapshot = await getDocs(q);
    const data = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    dispatch(firstTimerSuccess(data));
  };
};

export const fetchSecondimers = () => {
  return async (dispatch) => {
    dispatch(request());
    const collectionRef = collection(db, "members"); 
    const q = query(collectionRef, where('membership_status', '==', 'Second Timer'));
    const snapshot = await getDocs(q);
    const data = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    dispatch(secondTimerSuccess(data));
  };
};

export const fetchUserCellAttendance = (userId) => {
  return async (dispatch) => {
    dispatch(request());
      const usersCollection = collection(db, "cell_attendance");
      const usersQuery = query(usersCollection, where("user_id", "==", userId));
      const snapshot = await getDocs(usersQuery);
      const data = snapshot.docs.map(doc => doc.data());
    dispatch(cellAttendanceSuccess(data));
  };
};

export const moveToFirstTimer = (user) => {
  return async (dispatch) => {
    const userRef = doc(db, 'members', user.id);
    try {
      await setDoc(userRef, { 
        membership_status: 'First Timer',
        ...user
      });
      console.log('User created successfully!');
    } catch (error) {
      console.error('Error creating user: ', error);
    }
  }  
}


export const makeMember = (user) => {
  return async (dispatch) => {
    const userRef = doc(db, 'members', user.id);
    try {
      await setDoc(userRef, { 
        membership_status: 'Member',
        ...user
      });
      toast.success(`${user.name} is now a member`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error('Error creating user: ', error);
    }
  }  
}

export const moveToSecondTimer = (user) => {
  return async (dispatch) => {
    const userRef = doc(db, 'members', user.id);
    try {
      await setDoc(userRef, {
        date_moved_second_timer: serverTimestamp(), 
        membership_status: 'Second Timer',
        ...user,
      });
      toast.success(`${user.name} has been marked as a Second Timer`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      window.reload();
    } catch (error) {
      console.error('Error creating user: ', error);
    }
  }
}

export const moveAsSavedSoul = (user) => {
  return async (dispatch) => {
   //  dispatch(request());
    const userRef = doc(db, 'savedsouls', user.id);
    try {
      await setDoc(userRef, {
        date_saved: serverTimestamp(), 
       isSavedSoul: true,
        ...user
      });
      toast.success(`${user.name} has been marked as a saved soul`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      toast.error(`Error marking ${user.name} as saved soul due to ${error}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }
}

export const fetchUserChurchAttendance = (userId) => {
  return async (dispatch) => {
    try {
      const usersCollection = collection(db, "church_attendance");
      const usersQuery = query(usersCollection, where("user_id", "==", userId));
      const snapshot = await getDocs(usersQuery);
      const data = snapshot.docs.map(doc => doc.data());
      dispatch(churchAttendanceSuccess(data));
    } catch (error) {
      console.log('error', error)
    }
  };
};

export const deleteBranchByID = (churchId) => {
  return async (dispatch) => {
    dispatch(request());
    try {
    const docRef = doc(db, "members", churchId); 
    await deleteDoc(docRef);
    fetchMembers();
    window.location.reload();
    toast.success('Document deleted successfully', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
   
    } catch (error) {
      console.log('error: ', error)
        toast.error(`Error removing document: ${error}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
    }
  };
};


export const assignDesignate = (user) => {};

export const moveToMember = (user) => {
  return async (dispatch) => {
    const memberRef = collection(db, 'members');
    try {
      await addDoc(memberRef, { 
        membership_status: 'members',
        ...user
      });

      const docRef = doc(db, 'savedsouls', user.id);
      await deleteDoc(docRef);

      toast.success(`${user.name} is now a member`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      fetchSavedSouls();
      window.location.reload();
    } catch (error) {
      console.error('Error creating user: ', error);
    }
  } 
};

export const updateMemberProfile = async (editedUser, userId, toggleEditMode) => {
  try {
    const userRef = doc(db, 'members', userId); 
    await setDoc(userRef, editedUser, { merge: true });
    toast.success(`User updated successfully!`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    fetchMembers();
    toggleEditMode(); 
    window.location.reload();
  } catch (error) {
    console.error('Error saving user data: ', error);
    toast.error(`Error saving user data`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};


export const request = () => {
  return {
    type: FETCH_MEMBERS_REQUEST,
  };
};

export const success = (members) => {
  return {
    type: FETCH_MEMBERS_SUCCESS,
    payload: members,
  };
};

export const savedSoulSuccess = (members) => {
  return {
    type: FETCH_SAVED_SOULS_SUCCESS,
    payload: members,
  };
};


export const firstTimerSuccess = (members) => {
  return {
    type: FETCH_FIRST_TIMERS_SUCCESS,
    payload: members,
  };
};

export const secondTimerSuccess = (members) => {
  return {
    type: FETCH_SECOND_TIMER_SUCCESS,
    payload: members,
  };
};

export const cellAttendanceSuccess = (members) => {
  return {
    type: FETCH_CELL_SUCCESS,
    payload: members,
  };
};

export const churchAttendanceSuccess = (members) => {
  return {
    type: FETCH_CHURCH_SUCCESS,
    payload: members,
  };
};

export const failure = (error) => {
  return {
    type: FETCH_MEMBERS_FAILURE,
    payload: error,
  };
};


