import React, { useEffect, useState, Fragment } from "react";
import Helmet from "react-helmet";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
} from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import { fetchCellAttendance, updateDocument } from "../../../Redux/actions/attendanceAction";
import { Header, SideBar, Breadcrumb } from "../../Partials";

const CellMembers = (props) => {
  const dispatch = useDispatch();

  const initialUsers = [
    { id: 1, name: 'John Doe', email: 'john@example.com', attendedCell: 'Yes', attendedChurch: 'Yes', cellReason: '', churchReason: '', cellDate: '', churchDate: '' },
    { id: 2, name: 'Jane Smith', email: 'jane@example.com', attendedCell: 'No', attendedChurch: 'Yes', cellReason: 'Out of town', churchReason: '', cellDate: '', churchDate: '' },
    { id: 3, name: 'Bob Johnson', email: 'bob@example.com', attendedCell: 'Yes', attendedChurch: 'No', cellReason: '', churchReason: 'Illness', cellDate: '', churchDate: '' },
    { id: 4, name: 'Alice Brown', email: 'alice@example.com', attendedCell: 'No', attendedChurch: 'No', cellReason: '', churchReason: '', cellDate: '', churchDate: '' },
    { id: 5, name: 'Charlie Davis', email: 'charlie@example.com', attendedCell: 'Yes', attendedChurch: 'Yes', cellReason: '', churchReason: '', cellDate: '', churchDate: '' },
    // ... more users
  ];

  useEffect(() => {
    dispatch(fetchCellAttendance());
  }, [dispatch]);

  const attendance = useSelector((state) => state.Attendance);

  // State for managing users and the currently edited user
  const [users, setUsers] = useState(initialUsers);
  const [editingUser, setEditingUser] = useState(null);
  const [errors, setErrors] = useState({ cellReason: '', churchReason: '' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (user) => {
    setEditingUser({ ...user });
    setErrors({ cellReason: '', churchReason: '' });
  };

  const handleSave = () => {
    // Validation for reasons when attendance is 'No'
    if (editingUser.attendedCell === 'No' && !editingUser.cellReason) {
      setErrors((prevErrors) => ({ ...prevErrors, cellReason: 'Please provide a reason for not attending Cell' }));
      return;
    }
    if (editingUser.attendedChurch === 'No' && !editingUser.churchReason) {
      setErrors((prevErrors) => ({ ...prevErrors, churchReason: 'Please provide a reason for not attending Church' }));
      return;
    }
console.log('editingUser', editingUser);
    // Save the user data
    setUsers(users.map(user => user.id === editingUser.id ? editingUser : user));
    setEditingUser(null);
    setErrors({ cellReason: '', churchReason: '' });
  };

  // Handle change for the inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditingUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  // Pagination: Get the users for the current page
  const paginatedUsers = users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <React.Fragment>
      <Helmet>
        <title>Cell Members</title>
      </Helmet>

      <Header />
      <SideBar />
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <Breadcrumb crumbItem={"Cell"} crumb={"Mark Attendance"} />
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <Button
                    variant="contained"
                    href="dashboard/branch/create"
                    color="primary"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    View Cells
                  </Button>{" "}
                  <Button variant="contained" color="secondary">
                    Back
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <Paper>
            <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Cell Attendance</TableCell>
                  <TableCell>Church Attendance</TableCell>
                  <TableCell>Reason for Not Attending Cell</TableCell>
                  <TableCell>Reason for Not Attending Church</TableCell>
                  <TableCell>Cell Date (for Cell Attendance)</TableCell> {/* More descriptive header */}
                  <TableCell>Church Date (for Church Attendance)</TableCell> {/* More descriptive header */}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedUsers.map((user) => (
                  <TableRow key={user.id}>
                    {editingUser && editingUser.id === user.id ? (
                      <>
                       
                        <TableCell>
                          <FormControl fullWidth>
                            <InputLabel>Cell Attendance</InputLabel>
                            <Select
                              name="attendedCell"
                              value={editingUser.attendedCell}
                              onChange={handleInputChange}
                            >
                              <MenuItem value="Yes">Yes</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                            </Select>
                            {editingUser.attendedCell === 'No' && (
                              <TextField
                                name="cellReason"
                                label="Reason for not attending Cell"
                                value={editingUser.cellReason}
                                onChange={handleInputChange}
                                fullWidth
                                margin="dense"
                                error={!!errors.cellReason}
                                helperText={errors.cellReason}
                              />
                            )}
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <FormControl fullWidth>
                            <InputLabel>Church Attendance</InputLabel>
                            <Select
                              name="attendedChurch"
                              value={editingUser.attendedChurch}
                              onChange={handleInputChange}
                            >
                              <MenuItem value="Yes">Yes</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                            </Select>
                            {editingUser.attendedChurch === 'No' && (
                              <TextField
                                name="churchReason"
                                label="Reason for not attending Church"
                                value={editingUser.churchReason}
                                onChange={handleInputChange}
                                fullWidth
                                margin="dense"
                                error={!!errors.churchReason}
                                helperText={errors.churchReason}
                              />
                            )}
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <TextField
                            name="cellDate"
                            type="date"
                            label="Cell Attendance Date"
                            value={editingUser.cellDate}
                            onChange={handleInputChange}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            name="churchDate"
                            type="date"
                            label="Church Attendance Date" 
                            value={editingUser.churchDate}
                            onChange={handleInputChange}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <Button onClick={handleSave} color="primary">Save</Button>
                          <Button onClick={() => setEditingUser(null)} color="secondary">Cancel</Button>
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>{user.name}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.attendedCell}</TableCell>
                        <TableCell>{user.attendedChurch}</TableCell>
                        <TableCell>{user.cellReason || '-'}</TableCell>
                        <TableCell>{user.churchReason || '-'}</TableCell>
                        <TableCell>{user.cellDate}</TableCell> {/* Display Cell Date */}
                        <TableCell>{user.churchDate}</TableCell> {/* Display Church Date */}
                        <TableCell>
                          <Button onClick={() => handleEdit(user)} color="primary">Edit</Button>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            </TableContainer>

            <TablePagination
              component="div"
              count={users.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </Paper>

        </div>
      </div>
    </React.Fragment>
  );
};

export default CellMembers;
