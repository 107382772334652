import {
  FETCH_ADMINUSERS_REQUEST,
  FETCH_ADMINUSERS_SUCCESS,
  FETCH_ADMINUSERS_FAILURE,
} from "../actions/action-types";

const initialState = {
  loading: false,
  error: "",
  users: [],

};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADMINUSERS_REQUEST:
         return {
           ...state,
           loading: true,
         };
    case FETCH_ADMINUSERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
        error: null,
      };

    case FETCH_ADMINUSERS_FAILURE:
      return {
        ...state,
        loading: false,
        users: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
