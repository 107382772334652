import React from "react";
import axios from "axios";
import * as Yup from "yup";
// import uuid from "react-uuid";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import { Header, SideBar, Breadcrumb } from "../../Partials";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { useAlert } from "react-alert";
import { addDoc, collection } from "@firebase/firestore";

import { db } from "../../../Redux/firebase";


const validationSchema = Yup.object().shape({
  name: Yup.string().required("required")
});

const styles = (theme) => ({
  formControl: {
    marginTop: "18px",
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(1) * 2,
  },
});

const CreateDepartment = (props) => {
  let history = useHistory();
  const alert = useAlert();
  const { classes } = props;

  return (
    <React.Fragment>
      <Helmet>
        <title>Create Department</title>
      </Helmet>
      <ToastContainer />
      <Header />
      <SideBar />
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
             
              <Breadcrumb crumbItem={"Departments"} crumb={"Create"} />{" "}
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <Button
                    href="/dashboard/department/list"
                    variant="contained"
                    color="primary"
                  >
                    View Departments
                  </Button>{" "}
                  <Button
                    onClick={() => history.goBack()}
                    variant="contained"
                    color="secondary"
                  >
                    Go Back
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Create Department</h5>
                  <Formik
                    initialValues={{
                      name: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      setSubmitting(true);
                     try {
                        const data =  { name: values.name }

                       await addDoc(collection(db, "departments"), data);
                         
                            toast.success(" Department Successfully Created", {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });
                            resetForm();
                            setSubmitting(false);
                          } catch (error) {
                          toast.error(`${error}`, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });
                        }
                    }}
                  >
                    {({
                      values,
                      handleSubmit,
                      isSubmitting,
                      handleChange,
                      touched,
                      errors,
                      handleBlur,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-6 col-sm-12 col-lg-6 col-xs-12">
                            <div className="form-group-material">
                              <TextField
                                fullWidth
                                margin="normal"
                                id="name"
                                label="Name of department"
                                name="name"
                                onChange={handleChange}
                                value={values.name}
                                onBlur={handleBlur}
                                error={errors.name && touched.name}
                                helperText={
                                  errors.name && touched.name && errors.name
                                }
                              />
                            </div>
                          </div>

                         
                          <div className="form-group row">
                            <br />
                            <br />
                            <div className="col-sm-12 offset-sm-1">
                              <button
                                type="reset"
                                className="btn btn-secondary"
                              >
                                Cancel
                              </button>{" "}
                              <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isSubmitting}
                              >
                                Create Department
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

CreateDepartment.propTypes = {
  User: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  User: state.User.user,
});
export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(CreateDepartment));
