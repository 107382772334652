import React, { memo } from "react";
import MaterialTable from "material-table";
import { useDispatch } from "react-redux";
import { moveToSecondTimer } from "../../../../Redux/actions/MemberActions";

const FirstTimerTable = ({ members, history }) => {

  const dispatch = useDispatch();
  return (
    <div>
      <MaterialTable
        title="First Timers"
        columns={[
          { title: "Full Name", field: "name" },
          { title: "Email", field: "email" },
          { title: "Phone", field: "phone" },
          { title: "Gender", field: "gender" },
          { title: "Address", field: "address" },
          { title: "Occupation", field: "occupation" },
          { title: "Cell Leader", field: "cell_leader_assigned_to" },
          { title: "MVP", field: "mvp_assigned_to" },
        ]}
        data={members}
        actions={[
          {
            icon: "move_up",
            tooltip: "Move To Second Timer",
             onClick: (event, rowData) => dispatch(moveToSecondTimer(rowData)),
          },
          {
            icon: "contacts",
            tooltip: "View Member",
             onClick: (event, rowData) => history.push(`/dashboard/members/${rowData.id}`, { user: rowData }),
          },
        ]}

        options={{
          exportButton: true,
          sorting: true,
        }}
        
      />
    </div>
  );
};

export default memo(FirstTimerTable);


