import { doc, setDoc, onSnapshot, collection, query, where, addDoc, getDocs, deleteDoc } from "@firebase/firestore"; 
import { createUserWithEmailAndPassword } from "@firebase/auth";
import { toast } from "react-toastify";
import { db, auth } from "../firebase";
import {
  FETCH_ADMINUSERS_REQUEST,
  FETCH_ADMINUSERS_SUCCESS,
  FETCH_ADMINUSERS_FAILURE,
} from "./action-types";

const API_URL = process.env.REACT_APP_BASEURL;

export const fetchAdminUsers = () => {
  return async (dispatch) => {
    dispatch(request());
    const collectionRef = collection(db, "adminusers"); 
    const snapshot = await getDocs(collectionRef);
    const data = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    dispatch(success(data));
  };
};

export const deleteUserByID = (churchId) => {
  return async (dispatch) => {
   // dispatch(request());
    try {
    const docRef = doc(db, "adminusers", churchId); 
    await deleteDoc(docRef);
   window.location.reload();
    toast.success('Admin User deleted successfully', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
   
    } catch (error) {
      console.log('error: ', error)
        toast.error(`Error deleting Admin User: ${error}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
    }
  };
};

export const registerAdminUser = (data) => {
  return async (dispatch) => {
    dispatch(request());
    const { email, password, name, role, created_at } = data;
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password)
      const user = userCredential.user;
      const userId =  user.uid;
      const userRef = doc(db, 'adminusers', userId);
      const userData = { email, name, role, created_at, enabled: false };
      await setDoc(userRef, userData);
      toast.success('Admin User added successfully', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      dispatch(failure(error));
      toast.error(`Error adding Admin User: ${error}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const request = () => {
  return {
    type: FETCH_ADMINUSERS_REQUEST,
  };
};

export const success = (users) => {
  return {
    type: FETCH_ADMINUSERS_SUCCESS,
    payload: users,
  };
};

export const failure = (error) => {
  return {
    type: FETCH_ADMINUSERS_FAILURE,
    payload: error,
  };
};
