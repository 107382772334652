import { doc, setDoc, onSnapshot, collection, query, where, addDoc, getDocs, deleteDoc } from "@firebase/firestore"; 
import { FETCH_COURSES_SUCCESS, FETCH_COURSES_REQUEST, FETCH_COURSES_FAILURE } from "./action-types";
import { db } from "../firebase";

import {
  FETCH_CANDIDATE_SUCCESS,
  FETCH_CANDIDATE_REQUEST,
  FETCH_CANDIDATE_FAILURE,
} from "./action-types";

const API_URL = process.env.REACT_APP_BASEURL;

export const fetchCandidates = () => {
  return async (dispatch) => {
    dispatch(request());
      try {
          const usersCollection = collection(db, 'members'); 
          const q = query(
            usersCollection,
            where('school', '!=', null),
           // where('school', '!=', [])
          );
          const snapshot = await getDocs(q);
           
            const data = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            console.log('school', data);
            dispatch(sucess(data));
          } catch (error) {
            console.log('error: ', error)
            dispatch(failure(error));
          }
  };
};

export const request = () => ({ type: FETCH_CANDIDATE_REQUEST });

export const sucess = (candidateItems) => ({
  type: FETCH_CANDIDATE_SUCCESS,
  payload: candidateItems,
});

export const failure = (error) => {
  return {
    type: FETCH_CANDIDATE_FAILURE,
    payload: error,
  };
};


