import React, { memo } from "react";
import MaterialTable from "material-table";

const ChurchAttendanceTable = ({ attendance }) => {

  return (
    <div>
      <MaterialTable
        title="Church Attendance"
        columns={[
          { title: "Date", field: "date" },
          { title: "Status", field: "status" },
        //  { title: "Reason", field: "reason" },
        ]}
        data={attendance}
        actions={[
          // {
          //   icon: "contacts",
          //   tooltip: "View Member",
          //   onClick: (event, rowData) => console.log("edit", rowData),
          // },
        ]}

        options={{
          exportButton: true,
          sorting: true,
        }}
        
      />
    </div>
  );
};

export default memo(ChurchAttendanceTable);


