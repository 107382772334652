import { combineReducers } from "redux";
import CourseReducer from "./courseReducer";
import EventReducers from "./eventReducer";
import LessonReducer from "./lessonReducer";
import AssigmentReducer from "./assignmentReducer";
import SermonReducer from "./sermonReducers";
import candidateReducer from "./candidateReducer";
import branchReducer from "./branchReducer";
import memberReducer from "./memberReducer";
import CellReducer from "./cellsReducer";
import { galleryCoverReducer, galleriesReducer } from "./galleryReducer";
import User from "./userReducer";
import attendanceReducer from "./attendanceReducer";
import AdminUser from "./adminUserReducer";
import departments from './departmentReducer'


const rootReducer = combineReducers({
  courses: CourseReducer,
  events: EventReducers,
  lessons: LessonReducer,
  assignments: AssigmentReducer,
  sermon: SermonReducer,
  gpaCandidates: candidateReducer,
  branches: branchReducer,
  coverGallery: galleryCoverReducer,
  galleries: galleriesReducer,
  churchMembers: memberReducer,
  User,
  Cell: CellReducer,
  Attendance: attendanceReducer,
  adminuser: AdminUser,
  departments
});
export default rootReducer;
