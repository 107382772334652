import React from 'react'

const LoaderCard = () => {
    return (
        <div className="col-md-4" style={{ margin: "20px auto" }}>
            <div className="text-info">
                <div className="d-flex justify-content-center">
                    Loading..
            <div className="sk-circle">
                        <div className="sk-circle1 sk-child"></div>
                        <div className="sk-circle2 sk-child"></div>
                        <div className="sk-circle3 sk-child"></div>
                        <div className="sk-circle4 sk-child"></div>
                        <div className="sk-circle5 sk-child"></div>
                        <div className="sk-circle6 sk-child"></div>
                        <div className="sk-circle7 sk-child"></div>
                        <div className="sk-circle8 sk-child"></div>
                        <div className="sk-circle9 sk-child"></div>
                        <div className="sk-circle10 sk-child"></div>
                        <div className="sk-circle11 sk-child"></div>
                        <div className="sk-circle12 sk-child">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoaderCard
