import React from "react";
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { FormHelperText } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { Helmet } from 'react-helmet';
import { Formik, Form } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { doc, setDoc, onSnapshot, collection, query, where, addDoc } from "@firebase/firestore"; 
import * as Yup from "yup";

import { db } from "../../../Redux/firebase";
import { Header, SideBar, Breadcrumb } from "../../Partials";
import Button from '@material-ui/core/Button';
//API URL
// const API_URL = process.env.REACT_APP_BASEURL;

const styles = theme => ({
    formControl: {
        marginTop: '18px',
        minWidth: "100%",
    },
    selectEmpty: {
        marginTop: theme.spacing(1) * 2,
    },
});

const validationSchema = Yup.object().shape({
    coordinator: Yup.string().required("required"),
    gender: Yup.string().required("required"),
    email: Yup.string().email("Invalid email format").required("required"),
    phone: Yup.string().required("required"),
    address: Yup.string().required("required"),
    country: Yup.string().required("required"),
    state: Yup.string().required("required"),
    lga: Yup.string().required("required"),
    yearPlanted:Yup.string().required("required"),
    monthPlanted: Yup.string().required("required"),
    zip: Yup.string().required("required"),
});


const CreateCell = (props) => {
    const { classes } = props;
  
       
        return (
            <React.Fragment>
                <Helmet><title>Create Cell</title></Helmet>
                <Header />
                <SideBar />


                <div className="page-content">
                    <div className="container-fluid">
                    <div className="row">
                        <Breadcrumb crumbItem={'Home Cell'} crumb={'Create Cell'} />
                        <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                            <Button variant="contained" href="dashboard/cell/view" color="primary">
                                View Home Cells
                            </Button>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                            <h5 className="card-title">Create Cell</h5>
                            <Formik
                                initialValues={{
                                coordinator: "",
                                address: "",
                                gender: "",
                                email: "",
                                phone: "",
                                country: "",
                                state: "",
                                lga: "",
                                yearPlanted: "",
                                monthPlanted: "",
                                comment: "",
                                }}
                                validationSchema={validationSchema}
                                onSubmit={async (values, { setSubmitting, resetForm }) => {
                                setSubmitting(true);
                                try {
                                    const data = {
                                    coordinator: values.coordinator,
                                    gender: values.gender,
                                    email: values.email,
                                    phone: values.phone,
                                    address: values.address,
                                    country: values.country,
                                    state: values.state,
                                    lga: values.lga,
                                    yearPlanted: values.yearPlanted,
                                    monthPlanted: values.monthPlanted,
                                    created: Date.now(),
                                    };

                                    // Submit data to Firestore or handle accordingly
                                    const docRef = await addDoc(collection(db, "cells"), data);
                                    toast.success('Church Branch has been Successfully Created.', {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    });
                                    props.history.push("/dashboard/branch/view");
                                } catch (error) {
                                    toast.error(`${error}`, {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    });
                                }
                                }}
                            >
                                {({
                                values,
                                handleSubmit,
                                handleChange,
                                touched,
                                errors,
                                handleBlur,
                                }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div className="row">
                                    <div className="col-sm-12 col-md-6">
                                        <div className="form-group-material">
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            id="coordinator"
                                            label="Cell Leader - Who Planted The cell?"
                                            name="coordinator"
                                            error={touched.coordinator && Boolean(errors.coordinator)}
                                            helperText={touched.coordinator && errors.coordinator}
                                        />
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6">
                                        <div className="input-material">
                                        <FormControl className={classes.formControl} fullWidth margin="normal">
                                            <InputLabel htmlFor="gender">Gender</InputLabel>
                                            <Select
                                            native
                                            name="gender"
                                            id="gender"
                                            value={values.gender}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.gender && Boolean(errors.gender)}
                                            helperText={touched.gender && errors.gender}
                                            >
                                            <option value=""></option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            </Select>
                                        </FormControl>
                                        </div>
                                    </div>
                                    </div>

                                    {/* Row 2 */}
                                    <div className="row">
                                    <div className="col-sm-12 col-md-6">
                                        <div className="form-group-material">
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            id="email"
                                            label="Cell Leader's Email"
                                            name="email"
                                            error={touched.email && Boolean(errors.email)}
                                            helperText={touched.email && errors.email}
                                        />
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6">
                                        <div className="form-group-material">
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            id="phone"
                                            label="Cell Leader's Phone Number"
                                            name="phone"
                                            error={touched.phone && Boolean(errors.phone)}
                                            helperText={touched.phone && errors.phone}
                                        />
                                        </div>
                                    </div>
                                    </div>

                                    {/* Row 3 */}
                                    <div className="row">
                                    <div className="col-sm-12 col-md-6">
                                        <div className="form-group-material">
                                        <TextField
                                            multiline
                                            fullWidth
                                            margin="normal"
                                            id="address"
                                            label="Address (Cell Location)"
                                            name="address"
                                            error={touched.address && Boolean(errors.address)}
                                            helperText={touched.address && errors.address}
                                        />
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6">
                                        <div className="form-group-material">
                                        <FormControl className={classes.formControl} fullWidth margin="normal">
                                            <InputLabel htmlFor="country">Country</InputLabel>
                                            <Select
                                            native
                                            name="country"
                                            id="country"
                                            value={values.country}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.country && Boolean(errors.country)}
                                            helperText={touched.country && errors.country}
                                            >
                                            <option value=""></option>
                                            <option value="Nigeria">Nigeria</option>
                                            <option value="South Africa">South Africa</option>
                                            <option value="Canada">Canada</option>
                                            <option value="USA">USA</option>
                                            </Select>
                                            
                                        </FormControl>
                                        </div>
                                    </div>
                                    </div>

                                    {/* Row 4 */}
                                    <div className="row">
                                    <div className="col-sm-12 col-md-6">
                                        <div className="form-group-material">
                                        <FormControl className={classes.formControl} fullWidth margin="normal">
                                            <InputLabel htmlFor="state">State/Province</InputLabel>
                                            <Select
                                            native
                                            id="state"
                                            name="state"
                                            value={values.state}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.state && Boolean(errors.state)}
                                            helperText={touched.state && errors.state}
                                            >
                                            <option value=""></option>
                                            <option value="Abuja">Abuja</option>
                                            <option value="Lagos">Lagos</option>
                                            <option value="Port Harcourt">Port Harcourt</option>
                                            </Select>
                                           
                                        </FormControl>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6">
                                        <div className="form-group-material">
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            id="lga"
                                            label="LGA"
                                            name="lga"
                                            value={values.lga}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.lga && Boolean(errors.lga)}
                                            helperText={touched.lga && errors.lga}
                                        />
                                        </div>
                                    </div>
                                    </div>

                                    {/* Row 5 */}
                                    <div className="row">
                                    <div className="col-sm-12 col-md-6">
                                        <div className="form-group-material">
                                        <FormControl fullWidth margin="normal">
                                            <InputLabel htmlFor="yearPlanted">Year Planted</InputLabel>
                                            <Select
                                            native
                                            name="yearPlanted"
                                            id="yearPlanted"
                                            value={values.yearPlanted}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.yearPlanted && Boolean(errors.yearPlanted)}
                                            helperText={touched.yearPlanted && errors.yearPlanted}
                                            >
                                            <option value=""></option>
                                            {Array.from({ length: 72 }, (_, i) => 2019 + i).map((year) => (
                                                <option key={year} value={year}>
                                                {year}
                                                </option>
                                            ))}
                                            </Select>
                                           
                                        </FormControl>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6">
                                        <div className="form-group-material">
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            id="monthPlanted"
                                            label="Month Planted"
                                            name="monthPlanted"
                                            select
                                            value={values.monthPlanted}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.monthPlanted && Boolean(errors.monthPlanted)}
                                            helperText={touched.monthPlanted && errors.monthPlanted}
                                        >
                                            {["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"].map((month) => (
                                            <option key={month} value={month}>
                                                {month}
                                            </option>
                                            ))}
                                        </TextField>
                                        </div>
                                    </div>
                                    </div>

                                    {/* Row 6 */}
                                    <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group-material">
                                        <TextField
                                            fullWidth
                                            multiline
                                            margin="normal"
                                            id="comment"
                                            label="Comment - Optional"
                                            name="comment"
                                        />
                                        </div>
                                    </div>
                                    </div>

                                    {/* Submit/Cancel Buttons */}
                                    <div className="form-group row">
                                    <div className="col-sm-12 offset-sm-3">
                                        <button type="reset" className="btn btn-secondary">
                                        Cancel
                                        </button>{" "}
                                        <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                  >
                                    Create Cell
                                  </Button>
                                    </div>
                                    </div>
                                </Form>
                                )}
                            </Formik>
                            </div>
                        </div>
                        </div>
                    </div>

                    <ToastContainer />
                    </div>
                </div>
            </React.Fragment>
        );
    }



export default withStyles(styles)(CreateCell);
