import React, { memo } from "react";
import MaterialTable from "material-table";
import { useDispatch } from "react-redux";
import { moveToMember, assignDesignate } from "../../../../Redux/actions/MemberActions";

const SavedSoulsTable = ({ members, history }) => {
  const dispatch = useDispatch();
  return (
    <div>
      <MaterialTable
        title="Saved Souls"
        columns={[
          { title: "Full Name", field: "name" },
          { title: "Branch", field: "branch" },
          { title: "Phone", field: "phone" },
          { title: "Address", field: "address" },
          { title: "Nearest Bustop", field: "bustop" },
          { title: "Designate Assigned To", field: "assigned_to" },
        ]}
        data={members}
        actions={[
          {
            icon: "group",
            tooltip: "Mark as Member",
            onClick: (event, rowData) => dispatch(moveToMember(rowData)),
          },
          {
            icon: "follow_the_signs",
            tooltip: "Assign To Designate",
           // onClick: (event, rowData) => dispatch(assignDesignate(rowData)),
          }
        ]}

        options={{
          exportButton: true,
          sorting: true,
        }}
        
      />
    </div>
  );
};

export default memo(SavedSoulsTable);


