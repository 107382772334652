import { doc, setDoc, onSnapshot, collection, query, where, addDoc, getDocs, deleteDoc } from "@firebase/firestore"; 
import { toast } from "react-toastify";
import { db } from "../firebase";
import {
  FETCH_CELL_MEMBERS,
  FETCH_CELL_MEMBERS_SUCCESS,
  FETCH_CELL_MEMBERS_FAILURE,
  FETCH_CELLS,
  FETCH_CELL_SUCCESS,
  FETCH_CELL_REQUEST,
  FETCH_MEMBERS_REQUEST
 
} from "./action-types";

export const fetchCells = () => {
  return async (dispatch) => {
    dispatch(cellRequest());

      try {
            const collectionRef = collection(db, "cells"); 
            const snapshot = await getDocs(collectionRef);
            const data = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            dispatch(cellSuccess(data));
          } catch (error) {
            console.log('error: ', error)
            dispatch(failure(error));
          }
  };
};

export const fetchCellMembers = (userId) => {
  return async (dispatch) => {
    dispatch(request());
    const membersCollection = collection(db, 'members');
    const q = query(membersCollection, where('cell_leader_id', '==', userId));
    try {
      const querySnapshot = await getDocs(q);
      const membersData = querySnapshot.docs.map(doc => doc.data());
      dispatch(success(membersData));
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  };
};






export const request = () => {
  return {
    type: FETCH_MEMBERS_REQUEST,
  };
};


export const cellRequest = () => {
  return {
    type: FETCH_CELL_REQUEST,
  };
};

export const success = (members) => {
  return {
    type: FETCH_CELL_MEMBERS_SUCCESS,
    payload: members,
  };
};

export const cellSuccess = (cells) => {
  return {
    type: FETCH_CELL_SUCCESS,
    payload: cells,
  };
};


export const failure = (error) => {
  return {
    type: FETCH_CELL_MEMBERS_FAILURE,
    payload: error,
  };
};
