import { doc, collection, getDocs, deleteDoc, where, query } from "@firebase/firestore"; 
import { toast } from "react-toastify";
import { db } from "../firebase";
import {
  FETCH_DEPARTMENTS_REQUEST,
  FETCH_DEPARTMENT_SUCCESS,
  FETCH_DEPARTMENT_FAILURE,
  FETCH_DEPARTMENT_MEMBERS,
  FETCH_NON_DEPARTMENT_MEMBERS
} from "./action-types";


export const fetchDepartments = (deptId) => {
  return async (dispatch) => {
    dispatch(request());
    try {
      const collectionRef = collection(db, "departments"); 
    const snapshot = await getDocs(collectionRef);
    const data = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
      
      dispatch(success(data));
    } catch (error) {
      console.error("Error fetching departments: ", error);
      dispatch(failure(error));
      toast.error(`Error Fetching departments: ${error}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const fetchDeptMembers = (deptId) => {
  return async (dispatch) => {
    dispatch(request());
    const collectionRef = collection(db, "members"); 
    const departmentQuery = query(collectionRef, where("departmentId", "==", deptId));

    try {
      const snapshot = await getDocs(departmentQuery);
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      
      dispatch(memberSuccess(data));
    } catch (error) {
      console.error("Error fetching data: ", error);
      dispatch(failure(error));
      toast.error(`Error Fetching departments: ${error}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const fetchNonDeptMembers = (deptId) => {
  return async (dispatch) => {
    dispatch(request());
    const collectionRef = collection(db, "members");

    try {
        const departmentQuery = query(collectionRef);
        const snapshot = await getDocs(departmentQuery);
    
        const data = snapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .filter((member) => member.departmentId !== deptId);
        dispatch(nonMemberSuccess(data));
    } catch (error) {
      console.error("Error fetching Non Members: ", error);
      dispatch(failure(error));
      toast.error(`Error Fetching Non Members of department: ${error}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const deleteBranchByID = (deptId) => {
  return async (dispatch) => {
    dispatch(request());
    try {
    const docRef = doc(db, "departments", deptId); 
    await deleteDoc(docRef);
    window.location.reload();
    toast.success('Department has been deleted successfully', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
   
    } catch (error) {
      console.log('error: ', error)
        toast.error(`Error removing document: ${error}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
    }
  };
};

export const request = () => {
  return {
    type: FETCH_DEPARTMENTS_REQUEST,
  };
};

export const success = (branchItems) => {
  return {
    type: FETCH_DEPARTMENT_SUCCESS,
    payload: branchItems,
  };
};

export const memberSuccess = (members) => {
  return {
    type: FETCH_DEPARTMENT_MEMBERS,
    payload: members,
  };
};

export const nonMemberSuccess = (nonmembers) => {
  return {
    type: FETCH_NON_DEPARTMENT_MEMBERS,
    payload: nonmembers,
  };
};

export const failure = (error) => {
  return {
    type: FETCH_DEPARTMENT_FAILURE,
    payload: error,
  };
};
