import React from "react";
import MaterialTable from "material-table";
import { useDispatch, useSelector } from "react-redux";
import { deleteBranchByID } from "../../../../Redux/actions/branchActions";

const BranchListTable = ({ branches }) => {
  const dispatch = useDispatch();

  return (
    <div>
      <MaterialTable
        title="List Of Church Branches"
        columns={[
          { title: "Branch Pastor", field: "brnchPst" },
          { title: "Gender", field: "gender" },
          { title: "Email", field: "email" },
          { title: "Phone", field: "phone" },
          { title: "Country", field: "country" },
          { title: "State", field: "state" },
          { title: "Address", field: "address" },
        ]}
        data={branches}
        actions={[
          {
            icon: "edit",
            tooltip: "edit data",
            onClick: (event, rowData) => console.log("edit", rowData),
            
          },
        ]}
        editable={{
          onRowDelete: (oldData) => dispatch(deleteBranchByID(oldData.id)),
        }}
      />
    </div>
  );
};

export default BranchListTable;


