import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import Typography from "@material-ui/core/Typography";
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import MaterialTable from 'material-table';
import { writeBatch, doc } from "@firebase/firestore";
import { Button, MenuItem, Select, InputLabel, FormControl, Chip, Input } from "@material-ui/core";


import { fetchCourseLists } from "../../../../Redux/actions/courseActions";
import { fetchCandidates } from "../../../../Redux/actions/candidateActions";
import { fetchMembers } from "../../../../Redux/actions/MemberActions";
import { Header, SideBar, Breadcrumb } from "../../../Partials";
import CandidateTable from "./CandidateTables";
import { db } from "../../../../Redux/firebase";
import CustomModal from "../../Modal/CustomDialog";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "440",
  },
  typo: {
    fontSize: "1rem",
  },
  appBar: {
    padding: "10px",
  },
  progress: {
    // margin: theme.spacing(1) * 2,
    margin: "auto",
  },
  loader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "10vh",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  datePicker: {
    margin: theme.spacing(1),
  },
  formControl: {
    width: '100%',
  },
}));


const CandidateList = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const candidates = useSelector((state) => state.gpaCandidates);
  const [open, setOpen] = useState(false);
  const [selectedNames, setSelectedNames] = useState([]);
  const churchMembers = useSelector((state) => state.churchMembers);
  const courses = useSelector((state) => state.courses.courseItems)
    const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [selectedCourses, setSelectedCourses] = useState({}); 

    useEffect(() => {
      dispatch(fetchMembers());
      dispatch(fetchCandidates());
      dispatch(fetchCourseLists());
    }, [dispatch]);


  const handleClose = () => {
    setOpen(false);
  };

  // Handle course selection for a specific user
  const handleCourseChange = (userId, event) => {
    const newSelectedCourses = { ...selectedCourses };
    const selectedCourseIds = event.target.value;

    // Find the course objects for the selected course IDs
    const selectedCourseObjects = selectedCourseIds.map(
      (courseId) => courses.find((course) => course.id === courseId)
    );

    // Update selected courses for the specific user
    newSelectedCourses[userId] = Array.from(new Set(selectedCourseObjects)); // Ensure uniqueness
    setSelectedCourses(newSelectedCourses);
  };

  // Handle user selection via checkbox
  const handleUserSelection = (rowData) => {
    const isSelected = selectedNames.some((user) => user.id === rowData.id);
    if (isSelected) {
      const updatedSelection = selectedNames.filter((user) => user.id !== rowData.id);
      setSelectedNames(updatedSelection);
    } else {
      setSelectedNames([...selectedNames, rowData]);
    }
  };

    // Handle date change for selected courses
    const handleDateChange = (userId, courseId, type, value) => {
      const newSelectedCourses = { ...selectedCourses };
      const userCourses = newSelectedCourses[userId];
  
      // Find the course by its ID
      const courseIndex = userCourses.findIndex((course) => course.id === courseId);
  
      if (courseIndex !== -1) {
        if (type === "start") {
          userCourses[courseIndex].startDate = value;
        } else {
          userCourses[courseIndex].endDate = value;
        }
      }
  
      setSelectedCourses(newSelectedCourses);
    };

    const formatDate = (date) => {
      if (!date) return ''; // Return empty string if date is falsy (undefined, null, etc.)
      
      const d = new Date(date);
      if (isNaN(d.getTime())) return ''; // Return empty string if the date is invalid
    
      const year = d.getFullYear();
      const month = (`0${d.getMonth() + 1}`).slice(-2); // Add leading zero if month < 10
      const day = (`0${d.getDate()}`).slice(-2); // Add leading zero if day < 10
      return `${year}-${month}-${day}`;
    };
    


  const renderNames = () => (
    <>
       <MaterialTable
        title="Registered Members"
        columns={[
          { title: 'Name', field: 'name' },
          {
            title: 'Courses',
            render: (rowData) => (
              <FormControl className={classes.formControl}>
                <InputLabel id={`select-courses-label-${rowData.id}`}>
                  Select Courses
                </InputLabel>
                <Select
                  labelId={`select-courses-label-${rowData.id}`}
                  multiple
                  value={selectedCourses[rowData.id]?.map((course) => course.id) || []}
                  onChange={(event) => handleCourseChange(rowData.id, event)}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <div>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={courses.find((option) => option.id === value)?.title}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                >
                  {courses.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ),
          },
          {
            title: 'Select',
            render: (rowData) => (
              <input
                type="checkbox"
                checked={selectedNames.some((user) => user.id === rowData.id)}
                onChange={() => {
                  const isSelected = selectedNames.some((user) => user.id === rowData.id);
                  if (isSelected) {
                    const updatedSelection = selectedNames.filter((user) => user.id !== rowData.id);
                    setSelectedNames(updatedSelection);
                  } else {
                    setSelectedNames([...selectedNames, rowData]);
                  }
                }}
              />
            ),
          },
        ]}
        data={churchMembers.members}
        options={{
          search: true,
          paging: true,
          pageSize: 5,
        }}
        onSelectionChange={(rows) => {
          setSelectedNames(rows);
        }}
      />

  

    </>
  )

   // Perform batch update in Firestore
   const handleBatchUpdate = () => {
    if (selectedNames.length === 0) {
      toast.error(`Please select at least one Member!`, {
          position: "top-right",
          autoClose: 15000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      return;
    }

    const batch = writeBatch(db);

    selectedNames.forEach((user) => {
      const memberRef = doc(db, 'members', user.id);  
      const userData = {
        name: user.name,
        selectedCourses: selectedCourses[user.id] || [], 
        ...user
      };
     batch.set(memberRef, userData); // Add this write to the batch
    });


  
    batch
      .commit()
      .then(() => {
       // dispatch(fetchDeptMembers(id));
        setSelectedNames([]); 
        handleClose();
        toast.success(`Candidate Successfully Added!`, {
          position: "top-right",
          autoClose: 15000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        console.error('Error performing batch update: ', error);
      });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Candidates</title>
      </Helmet>
      <Header />
      <SideBar />

      {/* CLOSE SIDE BAR */}

      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <Breadcrumb crumbItem={"Dashboard"} crumb={"Candidates"} />
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ textDecoration: "none", color: "white" }}
                    onClick={() => { setOpen(true) }}
                  >
                   Add Candidates
                  </Button>{" "}
                  
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              {/* Branch Table:::::::::::::::::::::::::::::::::: */}

              {candidates.loading ? (
                <div className={classes.loader}>
                  {" "}
                  <CircularProgress className={classes.progress} size={60} />
                  <Typography color="inherit" className="flexs={12}pacer">
                    Loading...
                  </Typography>
                </div>
              ) : (
                <CandidateTable members={candidates.candidateItems} history={props.history} />
              )}
              {/* Member Table:::::::::::::::::::::::::::::::::: */}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />

      <CustomModal 
        OpenModal={open} 
        height={400}
        handleDialogClose={handleClose} 
        dialogWidth="md" 
        rightButton={true} 
        title="Add Member To Your Department"
        onButtonClick={handleBatchUpdate}
      >
        {renderNames()}
      </CustomModal>
    </React.Fragment>
  );
};

export default CandidateList;
