import {
  FETCH_DEPARTMENTS_REQUEST,
  FETCH_DEPARTMENT_SUCCESS,
  FETCH_DEPARTMENT_FAILURE,
  FETCH_DEPARTMENT_MEMBERS,
  FETCH_NON_DEPARTMENT_MEMBERS
} from "../actions/action-types";

const initialState = {
  loading: false,
  error: "",
  depts: [],
  deptMembers: [],
  nonMembers: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DEPARTMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        depts: action.payload,
        error: null,
      };

      case FETCH_DEPARTMENT_MEMBERS:
        return {
          ...state,
          loading: false,
          deptMembers: action.payload,
          error: null,
        };

        case FETCH_NON_DEPARTMENT_MEMBERS:
        return {
          ...state,
          loading: false,
          nonMembers: action.payload,
          error: null,
        };

        case FETCH_DEPARTMENT_FAILURE:
          return {
            ...state,
            loading: false,
            depts: [],
            error: action.payload,
          };
        default:
          return state;
      }
};

export default reducer;
