import React, { useEffect, useState } from "react";
import axios from "axios";
import * as Yup from "yup";
import { doc, getDocs, writeBatch, collection, query, where, addDoc } from "@firebase/firestore";
import Papa from 'papaparse'; 
import { db } from "../../../Redux/firebase";
import {
  TextField,
  MenuItem,
  Button,
  Grid,
  Typography
} from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom"
import { Formik, Form } from "formik";

import CustomModal from "../Modal/CustomDialog";
import { Header, SideBar } from "../../Partials";


const validationSchema = Yup.object().shape({
  name: Yup.string().required("name is required"),
  gender: Yup.string().required("gender is required"),
  address: Yup.string().required("address is required"),
  phone_number: Yup.number("must be a phone number").required("phone is required"),
  lga: Yup.string().required("zip is required"),
  state: Yup.string().required("state is required "),
  country: Yup.string().required("country is required"),
  email: Yup.string().email("Invalid email").required("Required"),
  cell_leader: Yup.string().required("cell leader is required"),
  occupation: Yup.string().required("occupation is required"),
  date_joined: Yup.date().required("date of intake is required"),
  membership_status: Yup.string().required("membership status is required"),
  mvp_assigned: Yup.string().required("mvp assigned is required"),
  marital_status: Yup.string().required("marital status is required"),
  branch: Yup.string().required("branch is required"),
  desire_to_join: Yup.string().required("Want to be a member is required"),
});

const CreateMember = (props) => {
  let history = useHistory()
  useEffect(() => {
  });

  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
 
  const handleClose = () => {
    setOpen(false);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (!file) {
      toast.error(`Please select a CSV file first`, {
        position: 'top-right',
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
  
    setLoading(true);
  
    // Parse CSV file
    Papa.parse(file, {
      complete: async (result) => {
        try {
          const rows = result.data;
  
          // Start batch write
          const batch = writeBatch(db);
          let duplicates = 0;
          let inserted = 0;
  
          for (const row of rows) {
            try {
              const userEmail = row.email;
              const membersRef = collection(db, 'members');
              const q = query(membersRef, where('email', '==', userEmail));
              const userSnapshot = await getDocs(q);
  
              if (userSnapshot.empty) {
                const docRef = doc(collection(db, 'members'));
                batch.set(docRef, row);
                inserted++;
              } else {
                duplicates++;
              }
            } catch (error) {
              // Log and notify if an error occurs while processing a row
              console.error(`Error processing row with email ${row.email}:`, error);
              toast.error(`Error processing row: ${row.email}`, { position: 'top-right' });
            }
          }
  
          try {
            // Commit the batch write
            await batch.commit();
            toast.success(`${inserted} new users added! ${duplicates} duplicates found.`, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
            });
            handleClose();
            history.push('/dashboard/members');
          } catch (error) {
            console.error('Error committing batch write:', error);
            toast.error('Error committing batch write: ' + error.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
            });
          }
        } catch (error) {
          console.error('Error parsing CSV:', error);
          toast.error('Error parsing the CSV file', { position: 'top-right' });
        } finally {
          setLoading(false);
        }
      },
      error: (error) => {
        console.error('CSV parsing failed', error);
        toast.error('CSV parsing error', { position: 'top-right' });
        setLoading(false);
      },
      header: true,
      skipEmptyLines: true,
    });
  };
  


  const maritalStatusOptions = ["single", "married", "widowed"];
  const membershipStatusOptions = ["First Timer", "Second Timer", "Member", "Guest"];
  const cellLeaders = ["Paul Brown", "Anna White", "Sarah Johnson"];

  const renderUpload = () => (
    <>
      <input type="file" accept=".csv" onChange={handleFileChange} />
      <button onClick={handleUpload} disabled={loading}>
        {loading ? 'Uploading...' : 'Upload CSV'}
      </button>
    </>
  )


  return (
    <React.Fragment>
      <ToastContainer />
      <Header />
      <SideBar />
      <div className="page-content">

        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/dashboard/">Dashboard</a></li>
                  <li className="breadcrumb-item"><a href="#">Create New Member</a></li>
                </ol>
              </nav>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <Button href="/dashboard/members" variant="contained" color="primary">View Members</Button>
                  {" "}
                  <Button variant="contained" color="primary" onClick={() => { setOpen(true) }}>Upload Multiple Records</Button>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                
                  <Formik
                    initialValues={{
                      name: "",
                      phone_number: "",
                      address: "",
                      date_joined: "",
                      marital_status: "",
                      occupation: "",
                      membership_status: "",
                      follow_up_period: "",
                      mvp_assigned: "",
                      status: "",
                      is_cell_leader: false,
                      lga: "",
                      branch: "",
                      cell_leader: "",
                      desire_to_join: "",

                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      setSubmitting(true);
                      try {
                      const data = {
                          name: values.name,
                          gender: values.gender,
                          email: values.email,
                          phone: values.phone_number,
                          address: values.address,
                          state: values.state,
                          country: values.country,
                          lga: values.lga,
                          cell_leader: values.cell_leader,
                          branch: values.branch,
                          desire_to_join: values.desire_to_join,
                          date_joined: values.date_joined,
                          occupation: values.occupation,
                          membership_status: values.membership_status,
                          mvp_assigned: values.mvp_assigned,
                          created_at: Date.now(),
                          marital_status: values.marital_status
                        };
                        const docRef = await addDoc(collection(db, "members"), data);
                     
                          resetForm();
                          setSubmitting(false);
                          toast.success(`${ values.membership_status} has been Successfully added`, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });
                          props.history.push("/dashboard/members");
                       
                        } catch(err) {
                          toast.error(`${err}`, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });
                        };
                    }}
                  >
                    {({
                      values,
                      handleSubmit,
                      isSubmitting,
                      handleChange,
                      touched,
                      errors,
                      handleBlur,
                    }) => (                 
                        <form onSubmit={handleSubmit}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <Typography variant="h5">Church Member Form</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                id="Full Name"
                                label="Full Name"
                                name="name"
                                onChange={handleChange}
                                value={values.name}
                                onBlur={handleBlur}
                                error={errors.name && touched.name}
                                helperText={(errors.name && touched.name) && errors.name}
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                label="Phone Number"
                                name="phone_number"
                                onChange={handleChange}
                                value={values.phone_number}
                                onBlur={handleBlur}
                                error={errors.phone_number && touched.phone_number}
                                helperText={(errors.phone_number && touched.phone_number) && errors.phone_number}
                                fullWidth
                                required
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <TextField
                                label="Email" 
                                name="email"
                                margin="normal"
                                onChange={handleChange}
                                value={values.email}
                                onBlur={handleBlur}
                                error={errors.email && touched.email}
                                helperText={(errors.email && touched.email) && errors.email}
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <TextField
                                label="Gender"
                                name="gender"
                                select
                                 margin="normal"
                                value={values.gender}
                                onBlur={handleBlur}
                                error={errors.gender && touched.gender}
                                helperText={(errors.gender && touched.gender) && errors.gender}
                                onChange={handleChange}
                                fullWidth
                              >
                                <MenuItem key={'Female'} value={'female'}>
                                  {'Female'}
                                </MenuItem>

                                <MenuItem key={'Male'} value={'male'}>
                                  {'Male'}
                                </MenuItem>
                                
                              </TextField>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                            <TextField
                                label="Country"
                                name="country"
                                select
                                margin="normal"
                                value={values.country}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.country && touched.country}
                                helperText={(errors.country && touched.country) && errors.country}
                                fullWidth
                              >
                                 <MenuItem key={''} value={''}> </MenuItem>
                                      <MenuItem key={"Nigeria"} value={"Nigeria"}>Nigeria</MenuItem>
                                      <MenuItem key={"South Africa"} value={"South Africa"}>South Africa</MenuItem>
                                      <MenuItem key={"Canada"} value={"Canada"}>Canada</MenuItem>
                                      <MenuItem key={"USA"} value={"USA"}>USA</MenuItem>
                                    </TextField>
                        
                                </Grid>
                           
                              <Grid item xs={12} sm={6}>
                                    <TextField
                                      label="State/Province"
                                      name="state"
                                       margin="normal"
                                      select
                                      fullWidth
                                      value={values.state}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={errors.state && touched.state}
                                      helperText={(errors.state && touched.state) && errors.state}
                                    >
                                      <MenuItem value=""></MenuItem>
                                      <MenuItem value={"Abuja"}>Abuja</MenuItem>
                                      <MenuItem value={"Lagos"}> Lagos</MenuItem>
                                      <MenuItem value={"Lagos Island"}> Lagos Island</MenuItem>
                                      <MenuItem value={"Port Harcourt"}>Port Harcourt</MenuItem>
                                      <MenuItem value={"Aba"}>Aba</MenuItem>
                                    </TextField>
                                </Grid>
                             

                             
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                      label="Local Government Area"
                                      name="lga"
                                       margin="normal"
                                      select
                                      fullWidth
                                      value={values.lga}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={errors.lga && touched.lga}
                                      helperText={(errors.lga && touched.lga) && errors.lga}
                                    >
                                      <MenuItem value=""></MenuItem>
                                      <MenuItem value={"Ikeja"}>Ikeja</MenuItem>
                                      <MenuItem value={"Ogudu"}> Ogudu</MenuItem>
                                      <MenuItem value={"Maryland"}> Maryland</MenuItem>
                                      <MenuItem value={"Ogba"}>Ogba</MenuItem>
                                      <MenuItem value={"Gbagada"}>Gbagada</MenuItem>
                                    </TextField>
                                </Grid>
                             

                            <Grid item xs={12} sm={6}>
                              <TextField
                                label="Address"
                                name="address"
                                 margin="normal"
                                value={values.address}
                                onBlur={handleBlur}
                                error={errors.address && touched.address}
                                helperText={(errors.address && touched.address) && errors.address}
                                onChange={handleChange}
                                fullWidth
                                multiline
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <TextField
                                label="Date Joined"
                                name="date_joined"
                                type="date"
                                 margin="normal"
                                value={values.date_joined}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.date_joined && touched.date_joined}
                                helperText={(errors.date_joined && touched.date_joined) && errors.date_joined}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <TextField
                                label="Marital Status"
                                name="marital_status"
                                 margin="normal"
                                select
                                value={values.marital_status}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.marital_status && touched.marital_status}
                                helperText={(errors.marital_status && touched.marital_status) && errors.marital_status}
                                fullWidth
                              >
                                {maritalStatusOptions.map((status) => (
                                  <MenuItem key={status} value={status}>
                                    {status.toString().toLocaleUpperCase()}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>

                            {(values.marital_status === "Married" || values.marital_status === "married") && (
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  label="Number of Children"
                                  name="number_of_children"
                                  type="number"
                                   margin="normal"
                                  value={values.number_of_children}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={errors.number_of_children && touched.number_of_children}
                                  helperText={(errors.number_of_children && touched.number_of_children) && errors.number_of_children}
                                  fullWidth
                                />
                              </Grid>
                            )}


                            <Grid item xs={12} sm={6}>
                              <TextField
                                label="Membership Status"
                                name="membership_status"
                                 margin="normal"
                                select
                                value={values.membership_status}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.membership_status && touched.membership_status}
                                helperText={(errors.membership_status && touched.membership_status) && errors.membership_status}
                                fullWidth
                              >
                                {membershipStatusOptions.map((status) => (
                                  <MenuItem key={status} value={status}>
                                    {status}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <TextField
                                label="Occupation"
                                name="occupation"
                                 margin="normal"
                                value={values.occupation}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.occupation && touched.occupation}
                                helperText={(errors.occupation && touched.occupation) && errors.occupation}
                                fullWidth
                              />
                            </Grid>

                             <Grid item xs={12} sm={6}>
                              <TextField
                                label="Cell Leader"
                                name="cell_leader"
                                select
                                value={values.cell_leader}
                                onBlur={handleBlur}
                                error={errors.cell_leader && touched.cell_leader}
                                helperText={(errors.cell_leader && touched.cell_leader) && errors.cell_leader}
                                onChange={handleChange}
                                fullWidth
                              >
                                {cellLeaders.map((leader) => (
                                  <MenuItem key={leader} value={leader}>
                                    {leader}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                          
                            <Grid item xs={12} sm={6}>
                              <TextField
                                label="MVP Assigned"
                                name="mvp_assigned"
                                margin="normal"
                                value={values.mvp_assigned}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.mvp_assigned && touched.mvp_assigned}
                                helperText={(errors.mvp_assigned && touched.mvp_assigned) && errors.mvp_assigned}
                                fullWidth
                              />
                            </Grid>


                            <Grid item xs={12} sm={6}>
                              <TextField
                                label="Branch"
                                name="branch"
                                margin="normal"
                                select
                                fullWidth
                                value={values.branch}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.branch && touched.branch}
                                helperText={(errors.branch && touched.branch) && errors.branch}
                              >
                                <MenuItem value=""></MenuItem>
                                <MenuItem value={"Ikeja"}>Ikeja</MenuItem>
                                <MenuItem value={"Ibeju Lekki"}> Ibeju Lekki</MenuItem>
                                <MenuItem value={"Sangotedo"}> Sangotedo</MenuItem>
                                <MenuItem value={"Port Harcourt"}>Port Harcourt</MenuItem>
                                <MenuItem value={"Aba"}>Aba</MenuItem>
                                <MenuItem value={"Umuahia"}>Umuahia</MenuItem>
                              </TextField>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <TextField
                                label="Want to be a Member"
                                name="desire_to_join"
                                margin="normal"
                                select
                                fullWidth
                                value={values.desire_to_join}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.desire_to_join && touched.desire_to_join}
                                helperText={(errors.desire_to_join && touched.desire_to_join) && errors.desire_to_join}
                              >
                                <MenuItem value=""></MenuItem>
                                <MenuItem value={"Yes"}>Yes</MenuItem>
                                <MenuItem value={"May Be"}> May Be</MenuItem>
                                <MenuItem value={"No"}> No</MenuItem>
                             
                              </TextField>
                            </Grid>

                            <Grid item xs={12}>
                             
                            </Grid>
                            <Grid item xs={12}>
                              <Button type="submit" variant="contained" color="primary" fullWidth>
                                Submit
                              </Button>
                            </Grid>
                          </Grid>
                        </form>
                      )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomModal 
      OpenModal={open} 
      height={400}
      handleDialogClose={handleClose} 
      dialogWidth="sm"
      rightButton={false} 
      title="Upload CSV File for members (multiple members upload)"
      >
        {renderUpload()}
      </CustomModal>
    </React.Fragment>
  );
}

export default CreateMember;
