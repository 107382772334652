import React, { memo } from "react";
import MaterialTable from "material-table";

const CellListTable = ({ cells, history }) => {

  return (
    <div>
      <MaterialTable
        title="Home Cells"
        columns={[
          { title: "Planted By", field: "coordinator" },
          { title: "Address", field: "email" },
          { title: "Phone", field: "phone" },
          { title: "Gender", field: "gender" },
          { title: "Year Planted", field: "yearPlanted" },
          { title: "Month Planted", field: "monthPlanted" },
          // { title: "Country", field: "country" },
          // { title: "State", field: "state" },
          { title: "LGA", field: "location.city" },
          { title: "Handed Over To", field: "handed_to" },
          { title: "Year Handed Over", field: "year_handed_over" },
          { title: "Month  Handed Over", field: "month_handed_over" },
        ]}
        data={cells}
        // actions={[
        //   {
        //     icon: "pencil",
        //     tooltip: "View Member",
        //     onClick: (event, rowData) => history.push(`/dashboard/members/${rowData.id}`, { user: rowData }),
        //   },
        // ]}

        options={{
          exportButton: true,
          sorting: true,
        }}
        
      />
    </div>
  );
};

export default memo(CellListTable);


