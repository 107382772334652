import {
  FETCH_MEMBERS_SUCCESS,
  FETCH_MEMBERS_FAILURE,
  FETCH_MEMBERS_REQUEST,
  FETCH_CHURCH_SUCCESS,
  FETCH_CELL_SUCCESS,
  FETCH_FIRST_TIMERS_SUCCESS,
  FETCH_SECOND_TIMER_SUCCESS,
  FETCH_SAVED_SOULS_SUCCESS,
} from "../actions/action-types";
//here the reducer is going to evaluate any action that has been committed such as fetching and creating posts

const initialState = {
  loading: false,
  error: "",
  members: [],
  firstTimers: [],
  secondTimers: [],
  savedSouls: [],
  user_cell_attendance: {},
  user_church_attendance: {},
  savedSouls: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MEMBERS_REQUEST:
         return {
           ...state,
           loading: true,
         };
    case FETCH_MEMBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        members: action.payload,
        error: null,
      };

    case FETCH_FIRST_TIMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        firstTimers: action.payload,
      };

      case FETCH_SECOND_TIMER_SUCCESS:
        return {
          ...state,
          loading: false,
          secondTimers: action.payload,
        };


      case FETCH_SAVED_SOULS_SUCCESS:
        return {
          ...state,
          loading: false,
          savedSouls: action.payload,
        };

    case FETCH_CHURCH_SUCCESS:
      return {
        ...state,
        loading: false,
        user_church_attendance: action.payload,
        error: null,
      };


      case FETCH_CELL_SUCCESS:
        return {
          ...state,
          loading: false,
          user_cell_attendance: action.payload,
          error: null,
        };

    case FETCH_MEMBERS_FAILURE:
      return {
        ...state,
        loading: false,
        members: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
