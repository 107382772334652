import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Header, SideBar, Breadcrumb } from "../../Partials";
import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Helmet } from "react-helmet";
import MaterialTable from 'material-table';
import { writeBatch, doc } from "@firebase/firestore";
import { useParams } from 'react-router-dom';

import MemberTable from "./Table/MemberListTable";
import { fetchDeptMembers, fetchNonDeptMembers } from '../../../Redux/actions/departmentActions';
import CustomModal from "../Modal/CustomDialog";
 import { db } from "../../../Redux/firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "440",
  },
  typo: {
    fontSize: "1rem",
  },
  appBar: {
    padding: "10px",
  },
  progress: {
    // margin: theme.spacing(1) * 2,
    margin: "auto",
  },
  loader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "10vh",
  },
}));


const Department = (props) => {
  const classes = useStyles();
  const { id, name } = useParams();
  const state = useSelector((state) => state.departments);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [selectedNames, setSelectedNames] = useState([]);
 
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(fetchNonDeptMembers(id))
    dispatch(fetchDeptMembers(id));
  }, [dispatch]);



  const renderNames= () => (
    <MaterialTable
            title="Registered Members"
            columns={[
              { title: 'Name', field: 'name' },
              {
                title: 'Select',
                render: (rowData) => (
                  <input
                  type="checkbox"
                  checked={selectedNames.some((user) => user.id === rowData.id)}
                  onChange={() => {
                    const isSelected = selectedNames.some((user) => user.id === rowData.id);
                    if (isSelected) {
                      const updatedSelection = selectedNames.filter((user) => user.id !== rowData.id);
                      setSelectedNames(updatedSelection);
                    } else {
                      setSelectedNames([...selectedNames, rowData]);
                    }
                  }}
                />
                ),
              },
            ]}
            data={state.nonMembers}
            options={{
              search: true,
              paging: true,
              pageSize: 5,
            }}
        
            onSelectionChange={(rows) => {
              setSelectedNames(rows);
            }}
          />
  )


  // Perform batch update in Firestore
  const handleBatchUpdate = () => {
    if (selectedNames.length === 0) {
      toast.error(`Please select at least one Member!`, {
          position: "top-right",
          autoClose: 15000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      return;
    }

    const batch = writeBatch(db);

    selectedNames.forEach((user) => {
      const memberRef = doc(db, 'members', user.id);  
      batch.update(memberRef, {
      departmentName: name,
      departmentId: id,
      ...user
      });
    });

  
    batch
      .commit()
      .then(() => {
        dispatch(fetchDeptMembers(id));
        setSelectedNames([]); 
        handleClose();
        toast.success(`Member(s) has been successfully added to ${name} Department`, {
          position: "top-right",
          autoClose: 15000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        console.error('Error performing batch update: ', error);
      });
  };


  return (
    <React.Fragment>
      <Helmet>
        <title>View Members</title>
      </Helmet>
      <Header />
      <SideBar />

      {/* CLOSE SIDE BAR */}
      <CustomModal 
      OpenModal={open} 
      height={400}
      handleDialogClose={handleClose} 
      dialogWidth="sm" 
      rightButton={true} 
      title="Add Member To Your Department"
      onButtonClick={handleBatchUpdate}
      >
        {renderNames()}
      </CustomModal>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <Breadcrumb crumbItem={"Dashboard"} crumb={"All Members"} />
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ textDecoration: "none", color: "white" }}
                    onClick={() => { setOpen(true) }}
                  >
                    Add Member
                  </Button>{" "}
                  <Button variant="contained" color="primary"  href="dashboard/member/create">
                    Add Member into the System
                  </Button>
                </div>
              </div>
            </div>
          </div>
          

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              {/* Branch Table:::::::::::::::::::::::::::::::::: */}

              {state.loading ? (
                <div className={classes.loader}>
                  {" "}
                  <CircularProgress className={classes.progress} size={60} />
                  <Typography color="inherit" className="flexs={12}pacer">
                    Loading...
                  </Typography>
                </div>
              ) : (
                <MemberTable members={state.deptMembers} history={props.history} />
              )}
              {/* Member Table:::::::::::::::::::::::::::::::::: */}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    
    </React.Fragment>
  );
};

export default Department;
