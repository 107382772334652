import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Header, SideBar, Breadcrumb } from "../../Partials";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { ToastContainer, toast } from 'react-toastify';
import { doc, getDocs, writeBatch, collection, query, where, addDoc } from "@firebase/firestore";
import Papa from 'papaparse'; 
import { useHistory } from "react-router-dom"
import { db } from "../../../Redux/firebase";
import CustomModal from "../Modal/CustomDialog";

import MemberTable from "./Table/SavedSoulsTable";
import { fetchSavedSouls } from "../../../Redux/actions/MemberActions";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "440",
  },
  typo: {
    fontSize: "1rem",
  },
  appBar: {
    padding: "10px",
  },
  progress: {
    // margin: theme.spacing(1) * 2,
    margin: "auto",
  },
  loader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "10vh",
  },
}));

const SavedSouls = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const churchMembers = useSelector((state) => state.churchMembers);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  
  const handleClose = () => {
    setOpen(false);
  };
  
    const handleFileChange = (event) => {
      setFile(event.target.files[0]);
    };

  useEffect(() => {
    dispatch(fetchSavedSouls());
  }, [dispatch]);


  const handleUpload = () => {
    if (!file) {
      toast.error(`Please select a CSV file first`, {
        position: 'top-right',
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
  
    setLoading(true);
  
    // Parse CSV file
    Papa.parse(file, {
      complete: async (result) => {
        try {
          const rows = result.data;
  
          // Start batch write
          const batch = writeBatch(db);
          let duplicates = 0;
          let inserted = 0;
  
          for (const row of rows) {
            try {
              const phone = row.phone;
              const membersRef = collection(db, 'savedsouls');
              const q = query(membersRef, where('phone', '==', phone));
              const userSnapshot = await getDocs(q);
  
              if (userSnapshot.empty) {
                const docRef = doc(collection(db, 'savedsouls'));
                batch.set(docRef, row);
                inserted++;
              } else {
                duplicates++;
              }
            } catch (error) {
              // Log and notify if an error occurs while processing a row
              console.error(`Error processing row with Phone ${row.phone}:`, error);
              toast.error(`Error processing row: ${row.email}`, { position: 'top-right' });
            }
          }
  
          try {
            // Commit the batch write
            await batch.commit();
            toast.success(`${inserted} new users added! ${duplicates} duplicates found.`, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
            });
            handleClose();
            history.push('/dashboard/members');
          } catch (error) {
            console.error('Error committing batch write:', error);
            toast.error('Error committing batch write: ' + error.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
            });
          }
        } catch (error) {
          console.error('Error parsing CSV:', error);
          toast.error('Error parsing the CSV file', { position: 'top-right' });
        } finally {
          setLoading(false);
        }
      },
      error: (error) => {
        console.error('CSV parsing failed', error);
        toast.error('CSV parsing error', { position: 'top-right' });
        setLoading(false);
      },
      header: true,
      skipEmptyLines: true,
    });
  };

  const renderUpload = () => (
    <>
      <input type="file" accept=".csv" onChange={handleFileChange} />
      <button onClick={handleUpload} disabled={loading}>
        {loading ? 'Uploading...' : 'Upload CSV'}
      </button>
    </>
  )


  return (
    <React.Fragment>
      <Helmet>
        <title>MVP - First Timers </title>
      </Helmet>
      <Header />
      <SideBar />

      {/* CLOSE SIDE BAR */}

      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <Breadcrumb crumbItem={"Dashboard"} crumb={"Saved Souls"} />
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <Button
                    variant="contained"
                    href="dashboard/members"
                    color="primary"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    View Members
                  </Button>{" "}
                  <Button variant="contained" color="primary" onClick={() => { setOpen(true) }}>Upload Multiple Saved Souls</Button>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              {/* Branch Table:::::::::::::::::::::::::::::::::: */}

              {churchMembers.loading ? (
                <div className={classes.loader}>
                  {" "}
                  <CircularProgress className={classes.progress} size={60} />
                  <Typography color="inherit" className="flexs={12}pacer">
                    Loading...
                  </Typography>
                </div>
              ) : (
                <MemberTable members={churchMembers.savedSouls} history={props.history} />
              )}
              {/* Member Table:::::::::::::::::::::::::::::::::: */}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />

      <CustomModal 
      OpenModal={open} 
      height={400}
      handleDialogClose={handleClose} 
      dialogWidth="sm"
      rightButton={false} 
      title="Upload CSV File for members (multiple Saved Souls upload)"
      >
        {renderUpload()}
      </CustomModal>
    </React.Fragment>
  );
};

export default SavedSouls;
