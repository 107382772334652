import { doc, setDoc, onSnapshot, collection, query, where, addDoc, getDocs, updateDoc } from "@firebase/firestore"; 

import { db } from "../firebase";

import {
  FETCH_ATTENDANCE_SUCCESS,
FETCH_ATTENDANCE_REQUEST,
FETCH_ATTENDANCE_FAILURE,
UPDATE_USER_CHURCH_ATTENDANCE,
UPDATE_USER_CELL_ATTENDANCE
} from "./action-types";

const API_URL = process.env.REACT_APP_BASEURL;

export const fetchCellAttendance = () => {
  return async (dispatch) => {
    dispatch(request());
  try {
        const collectionRef = collection(db, "cell_attendance"); 
        const snapshot = await getDocs(collectionRef);
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log('data', data)
       // dispatch(sucess(data));
      } catch (error) {
        console.log('error: ', error)
        dispatch(failure(error));
      }
  };
};

export const fetchChurchAttendance = () => {
  return async (dispatch) => {
    dispatch(request());
  try {
        const collectionRef = collection(db, "church_attendance"); 
        const snapshot = await getDocs(collectionRef);
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log('data', data)
       // dispatch(sucess(data));
      } catch (error) {
        console.log('error: ', error)
        dispatch(failure(error));
      }
  };
};

export const updateDocument = async (docId, updatedData) => {
  try {
    const docRef = doc(db, 'attendancd', docId); // Reference to the document
    await updateDoc(docRef, updatedData); // Update the document
    updateUser(docId, updatedData);
    console.log('Document successfully updated!');
  } catch (error) {
    console.error('Error updating document: ', error);
  }
};

export const fetchUserCellAttendance = (userId) => {
   return async (dispatch) => {
    console.log('userId', userId) 
     dispatch(request());
    try {
      const cellAttendanceRef = collection(db, "cell_attendance");
      const q = query(cellAttendanceRef, where("userId", "==", userId));
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());
      });
      console.log('data', data);
    } catch (error) {
      console.log('error: ', error)
      dispatch(failure(error));
    }
   }
};


export const fetchUserChurchttendance = async (userId) => {
  return async (dispatch) => {
    console.log('userId', userId) 
     dispatch(request());
    try {
      const cellAttendanceRef = collection(db, "church_attendance");
      const q = query(cellAttendanceRef, where("userId", "==", userId));
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());
      });
      console.log('data', data);
    } catch (error) {
      console.log('error: ', error)
      dispatch(failure(error));
    }
   }
};


export const request = () => ({ type: FETCH_ATTENDANCE_REQUEST });

export const sucess = (candidateItems) => ({
  type: FETCH_ATTENDANCE_SUCCESS,
  payload: candidateItems,
});

export const failure = (error) => {
  return {
    type: FETCH_ATTENDANCE_FAILURE,
    payload: error,
  };
};

export const updateUser = (docId, updatedData) => ({
  type: 'UPDATE_ATTENDANCE',
  payload: { docId, updatedData },
});

export const updateUserCell = (cells) => ({
  type: UPDATE_USER_CELL_ATTENDANCE,
  payload: cells
});

export const updateUserChurch = (church_attendance) => ({
  type: UPDATE_USER_CHURCH_ATTENDANCE,
  payload: church_attendance,
});

