import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Button, TextField } from '@material-ui/core';
import { fetchDepartments } from '../../../Redux/actions/departmentActions';
import { ToastContainer, toast } from "react-toastify";
import { SideBar, Header, Breadcrumb } from '../../Partials';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
  root: {
    // border: "2px solid red",
    marginBottom: "20px"
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
});

const Departments = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [editableRow, setEditableRow] = useState(null);
  const [editData, setEditData] = useState({ name: '', peopleCount: 0 });
  const [viewMode, setViewMode] = useState(null);

  useEffect(() => {
    dispatch(fetchDepartments());
    return () => {
      dispatch(fetchDepartments());
    };
  }, [dispatch]);

const departments = useSelector((state) => state.departments.depts);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); 
  };

  const handleView = (dept) => {
   history.push(`/dashboard/department/members/${dept.name}/${dept.id}`);
  };

  const handleEdit = (department) => {
    setEditableRow(department.name);
    setEditData(department); 
  };


  const handleSave = () => {
    console.log('editData', editData);
    setEditableRow(null); // Stop editing
  };

 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  // Slice the departments array for the current page and rowsPerPage
  const currentDepartments = departments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


  return (
    <React.Fragment>
    <Helmet>
      <title>Departments</title>
    </Helmet>
    <ToastContainer />
    <Header />
    <SideBar />

    <div className="page-content">
      <div className="container">
      <div className="row">
        <Breadcrumb crumbItem={'Home Cell'} crumb={'Create Cell'} />
        <div className="col-12">
            <div className="card">
                <div className="card-body">
                    <Button variant="contained" href="dashboard/department/create" color="primary">
                        Create Department
                        </Button>
                    {" "}
                   
                </div>
            </div>
        </div>
    </div>
    <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Department</strong></TableCell>
              <TableCell align="right"><strong>Number of People</strong></TableCell>
              <TableCell align="center"><strong>Actions</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentDepartments.map((department, index) => (
              <TableRow key={index}>
                <TableCell>
                  {viewMode === department.name ? (
                    <span>{department.name}</span>
                  ) : editableRow === department.name ? (
                    <TextField
                      name="name"
                      value={editData.name}
                      onChange={handleInputChange}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  ) : (
                    department.name
                  )}
                </TableCell>

                <TableCell align="right">
                  {viewMode === department.name ? (
                    <span>{department.peopleCount}</span>
                  ) : editableRow === department.name ? (
                    <TextField
                      name="peopleCount"
                      value={editData.peopleCount}
                      onChange={handleInputChange}
                      variant="outlined"
                      size="small"
                      type="number"
                      fullWidth
                    />
                  ) : (
                    department.peopleCount
                  )}
                </TableCell>

                <TableCell align="center">
                  {/* Action buttons: View, Edit, Save/Cancel */}
                  {viewMode === department.name ? (
                    <>
                      <Button 
                        variant="contained" 
                        color="secondary" 
                        onClick={() => setViewMode(null)} // Exit view mode
                        style={{ marginRight: '8px' }}
                      >
                        Close
                      </Button>
                    </>
                  ) : editableRow === department.name ? (
                    <>
                      <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={handleSave} 
                        style={{ marginRight: '8px' }}
                      >
                        Save
                      </Button>
                      <Button 
                        variant="contained" 
                        color="secondary" 
                        onClick={() => setEditableRow(null)} // Cancel edit
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={() => handleView(department)} 
                        style={{ marginRight: '8px' }}
                      >
                        View
                      </Button>
                      <Button 
                        variant="contained" 
                        color="secondary" 
                        onClick={() => handleEdit(department)} 
                      >
                        Edit
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={departments.length} // Total number of departments
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </div>
    </div>
  </React.Fragment>
  );
};

Departments.propTypes = {
  className: PropTypes.string,
  candidates: PropTypes.array.isRequired
};


export default withStyles(styles)(Departments);
