//COURSE
export const FETCH_COURSES_SUCCESS = "FETCH_COURSES_SUCCESS";
export const FETCH_COURSES_REQUEST = "FETCH_COURSES_REQUEST";
export const FETCH_COURSES_FAILURE = "FETCH_COURSES_FAILURE";

//EVENT
export const FETCH_EVENTS_SUCCESS = "FETCH_EVENTS_SUCCESS";
export const FETCH_EVENTS_REQUEST = "FETCH_EVENTS_REQUEST";
export const FETCH_EVENTS_FAILURE = "FETCH_EVENTS_FAILURE";

//LESSON
export const FETCH_LESSONS_SUCCESS = "FETCH_LESSONS_SUCCESS";
export const FETCH_LESSONS_REQUEST = "FETCH_LESSONS_REQUEST";
export const FETCH_LESSONS_FAILURE = "FETCH_LESSONS_FAILURE";

//ASSIGMENT
export const FETCH_ASSIGMENT_SUCCESS = "FETCH_ASSIGMENT_SUCCESS";
export const FETCH_ASSIGMENT_REQUEST = "FETCH_ASSIGMENT_REQUEST";
export const FETCH_ASSIGMENT_FAILURE = "FETCH_ASSIGMENT_FAILURE";

//SERMON
export const FETCH_SERMON_SUCCESS = "FETCH_SERMON_SUCCESS";
export const FETCH_SERMON_REQUEST = "FETCH_SERMON_REQUEST";
export const FETCH_SERMON_FAILURE = "FETCH_SERMON_FAILURE";

//GPA CANDIDATE
export const FETCH_CANDIDATE_SUCCESS = "FETCH_CANDIDATE_SUCCESS";
export const FETCH_CANDIDATE_REQUEST = "FETCH_CANDIDATE_REQUEST";
export const FETCH_CANDIDATE_FAILURE = "FETCH_CANDIDATE_FAILURE";

//REGISTER CANDIDATE
export const FETCH_REGISTER_SUCCESS = "FETCH_REGISTER_SUCCESS";
export const FETCH_REGISTER_REQUEST = "FETCH_REGISTER_REQUEST";
export const FETCH_REGISTER_FAILURE = "FETCH_REGISTER_FAILURE";

//LOGIN

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILUR";
export const LOG_OUT = "LOG_OUT";

//REGISTER
export const REGISTER_SUCCESS = "LOGIN_SUCCESS";
export const STORE_REGISTER_ERROR_MSG = "STORE_USER_ERROR_MSG";

//MVP
export const DELETE_FIRST_TIMERS_SUCCESS = "DELETE_FIRST_TIMERS_SUCCESS";
export const DELETE_FIRST_TIMERS_REQUEST = "DELETE_FIRST_TIMERS_REQUEST";
export const DELETE_FIRST_TIMERS_FAILURE = "DELETE_FIRST_TIMERS_FAILURE";
export const FETCH_MEMBERS_SUCCESS = "FETCH_MEMBERS_SUCCESS";
export const FETCH_MEMBERS_FAILURE = "FETCH_MEMBERS_FAILURE";
export const FETCH_MEMBERS_REQUEST = "FETCH_MEMBERS_REQUEST";
export const FETCH_CHURCH_SUCCESS = "FETCH_CHURCH_SUCCESS";
export const FETCH_SECOND_TIMER_SUCCESS = "FETCH_SECOND_TIMER_SUCCESS"
export const FETCH_SAVED_SOULS_SUCCESS = "FETCH_SAVED_SOULS_SUCCESS"


export const FETCH_FIRST_TIMERS_SUCCESS = "FETCH_FIRST_TIMERS_SUCCESS";
export const FETCH_FIRST_TIMERS_REQUEST = "FETCH_FIRST_TIMERS_REQUEST";
export const FETCH_FIRST_TIMERS_FAILURE = "FETCH_REGISTER_FAILURE";

//FETCH CHURCH BRANCH
export const FETCH_BRANCHES_REQUEST = "FETCH_BRANCHES_REQUEST";
export const FETCH_BRANCHES_SUCCESS = "FETCH_BRANCHES_SUCCESS";
export const FETCH_BRANCHES_FAILURE = "FETCH_BRANCHES_FAILURE";

// FETCH IMAGE GALLERY COVER
export const FETCH_GALLERY_COVER_REQUEST = "FETCH_GALLERY_COVER_REQUEST";
export const FETCH_GALLERY_COVER_SUCCESS = "FETCH_GALLERY_COVER_SUCCESS";
export const FETCH_GALLERY_COVER_FAILURE = "FETCH_GALLERY_COVER_FAILURE";
// FETCH IMAGE GALLERY
export const FETCH_GALLERY_REQUEST = "FETCH_GALLERY_REQUEST";
export const FETCH_GALLERY_SUCCESS = "FETCH_GALLERY_SUCCESS";
export const FETCH_GALLERY_FAILURE = "FETCH_GALLERY_FAILURE";

export const FETCH_CELL_MEMBERS = "FETCH_CELL_MEMBERS";
export const FETCH_CELL_MEMBERS_SUCCESS = "FETCH_CELL_MEMBERS_SUCCESS";
export const FETCH_CELL_MEMBERS_FAILURE = "FETCH_CELL_MEMBERS_FAILURE";

export const FETCH_CELLS = "FETCH_CELLS";
export const FETCH_CELL_SUCCESS = "FETCH_CELL_SUCCESS";
export const FETCH_CELL_REQUEST = "FETCH_CELL_REQUEST";



export const FETCH_ATTENDANCE_SUCCESS = "FETCH_ATTENDANCE_SUCCESS";
export const FETCH_ATTENDANCE_REQUEST = "FETCH_ATTENDANCE_REQUEST";
export const FETCH_ATTENDANCE_FAILURE = "FETCH_ATTENDANCE_FAILURE";


export const FETCH_ADMINUSERS_REQUEST = "FETCH_ADMINUSERS_REQUEST";
export const FETCH_ADMINUSERS_SUCCESS = "FETCH_ADMINUSERS_SUCCESS";
export const FETCH_ADMINUSERS_FAILURE = "FETCH_ADMINUSERS_FAILURE";

// Department
export const FETCH_DEPARTMENTS_REQUEST = "FETCH_DEPARTMENTS_REQUEST";
export const FETCH_DEPARTMENT_SUCCESS = "FETCH_DEPARTMENT_SUCCESS";
export const FETCH_DEPARTMENT_FAILURE = "FETCH_DEPARTMENT_FAILURE";
export const FETCH_DEPARTMENT_MEMBERS = "FETCH_DEPARTMENT_MEMBERS";
export const FETCH_NON_DEPARTMENT_MEMBERS = "FETCH_NON_DEPARTMENT_MEMBERS";

export const UPDATE_USER_CHURCH_ATTENDANCE = 'UPDATE_USER_CHURCH_ATTENDANCE'
export const UPDATE_USER_CELL_ATTENDANCE = 'UPDATE_USER_CELL_ATTENDANCE'