import { FETCH_CELL_REQUEST, FETCH_CELL_SUCCESS, FETCH_CELL_MEMBERS } from "../actions/action-types";
//here the reducer is going to evaluate any action that has been committed such as fetching and creating posts

const initialState = {
    loading: false,
    error: "",
    cells: [],
    members: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

          case FETCH_CELL_REQUEST:
                    return {
                        ...state,
                        loading: true,
                    };
       
        case FETCH_CELL_SUCCESS:
            return {
                ...state,
                loading: false,
                cells: action.payload,
                error: "",
            };
        case FETCH_CELL_MEMBERS:
            return {
                ...state,
                loading: false,
                members: [],
                error: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
