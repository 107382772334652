import React, { memo } from "react";
import MaterialTable from "material-table";

const CandidateTable = ({ members, history }) => {

  return (
    <div>
      <MaterialTable
        title="Candidates"
        columns={[
          { title: "Full Name", field: "name" },
          { title: "Email", field: "email" },
          { title: "Phone", field: "phone" },
          { title: "Gender", field: "gender" },
          { title: "Date Started", field: "date_started" },
          { title: "Date Completed", field: "date_completed" },
          { title: "Address", field: "address" },
          { title: "Course", field: "course" },
          { title: "Status", field: "status" },
        ]}
        data={members}
        // actions={[
        //   {
        //     icon: "contacts",
        //     tooltip: "View Member",
        //     onClick: (event, rowData) => history.push(`/dashboard/members/${rowData.id}`, { user: rowData }),
        //   },
        // ]}

        options={{
          exportButton: true,
          sorting: true,
        }}
        
      />
    </div>
  );
};

export default memo(CandidateTable);


