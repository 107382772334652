import { signInWithEmailAndPassword } from "@firebase/auth";
import { doc, getDoc } from "@firebase/firestore";
import { history } from "../../_helpers"; 
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOG_OUT,
  LOGIN_REQUEST,
} from "./action-types";
import { auth, db } from '../firebase';

export function userLogin(data) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const { email, password } = data;
      const userCredential = await signInWithEmailAndPassword(auth, email, password)
        const user = userCredential.user;
        const userId = user.uid;
        const docRef = doc(db, "adminusers", userId); 
        const snapshot = await getDoc(docRef);
        if (snapshot.exists()) {
          const user_details = {...snapshot.data(), userId};
          console.log('user_details',user_details)
          dispatch(loginSuccess(user_details));
          window.location.href = `/dashboard`;
          return user_details;
        } 
      }catch(error) {
        console.log('error', error)
      };
  
  };


  function request(user) {
    return { type: LOGIN_REQUEST, user };
  }
  function loginSuccess(user) {
    return { type: LOGIN_SUCCESS, user };
  }
  function errorMessage(error) {
    return { type: LOGIN_FAILURE, error };
  }
}
//NOT IN USE AT THE MOMENT ******
export const userLogout = () => {
  // remove user from local storage to log user out
  // localStorage.removeItem('user');

  return { type: LOG_OUT };
};
