import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/GroupAdd'; 

// import Typography from "@material-ui/core/Typography";
// import AppBar from "@material-ui/core/AppBar";

// const API_URL = process.env.REACT_APP_BASEURL;

const CustomModal = (props) => {
  const { children, dialogWidth, OpenModal, handleDialogClose, height, title, onButtonClick, rightButton } = props;
  return (
    <React.Fragment>
      <Dialog
        disableEscapeKeyDown
        open={OpenModal}
        onClose={handleDialogClose}
        fullWidth={true}
        maxWidth={dialogWidth}
        aria-labelledby="custom-modal"
      >
         <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>{title}</span>
          {rightButton && (
          <IconButton edge="end" color="primary" onClick={onButtonClick} style={{marginLeft: 100}}>
             <CloseIcon /> Add Members
          </IconButton>
          )}
        </DialogTitle>

        <DialogContent  style={{ maxHeight: height, overflowY: 'auto' }}>
          {children}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default CustomModal;
