import { doc, setDoc, onSnapshot, collection, query, where, addDoc, getDocs, deleteDoc } from "@firebase/firestore"; 
import { toast } from "react-toastify";
import { db } from "../firebase";
import {
  FETCH_BRANCHES_REQUEST,
  FETCH_BRANCHES_SUCCESS,
  FETCH_BRANCHES_FAILURE,
} from "./action-types";

const API_URL = process.env.REACT_APP_BASEURL;

export const fetchBranchList = () => {
  return async (dispatch) => {
    dispatch(request());
    const collectionRef = collection(db, "branches"); 
    const snapshot = await getDocs(collectionRef);
    const data = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    dispatch(success(data));
  };
};

export const deleteBranchByID = (churchId) => {
  return async (dispatch) => {
   // dispatch(request());
    try {
    const docRef = doc(db, "branches", churchId); 
    await deleteDoc(docRef);
    fetchBranchList();
    window.location.reload();
    toast.success('Document deleted successfully', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
   
    } catch (error) {
      console.log('error: ', error)
        toast.error(`Error removing document: ${error}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
    }
  };
};

export const request = () => {
  return {
    type: FETCH_BRANCHES_REQUEST,
  };
};

export const success = (branchItems) => {
  return {
    type: FETCH_BRANCHES_SUCCESS,
    payload: branchItems,
  };
};

export const failure = (error) => {
  return {
    type: FETCH_BRANCHES_FAILURE,
    payload: error,
  };
};
