import React, { memo } from "react";
import MaterialTable from "material-table";

const MemberListTable = ({ members, history }) => {

  return (
    <div>
      <MaterialTable
        title="Departmental Members"
        columns={[
          { title: "Full Name", field: "name" },
          { title: "Email", field: "email" },
          { title: "Phone", field: "phone" },
          { title: "Gender", field: "gender" },
          { title: "Country", field: "country" },
          { title: "State", field: "state" },
          { title: "Address", field: "address" },
          { title: "Occupation", field: "occupation" },
          { title: "Department", field: "departmentName" },
        ]}
        data={members}
        actions={[
          {
            icon: "contacts",
            tooltip: "View Member",
            onClick: (event, rowData) => history.push(`/dashboard/members/${rowData.id}`, { user: rowData }),
          },
        ]}

        options={{
          exportButton: true,
          sorting: true,
        }}
        
      />
    </div>
  );
};

export default memo(MemberListTable);


