import React, { useEffect, useState } from "react";
import { 
  Grid, 
  Card, 
  CardContent, 
  Tab, 
  Tabs, 
  Typography, 
  Avatar, 
  makeStyles, 
  ListItemText, 
  Button, 
  createTheme, 
  List, 
  ListItem, 
  ListItemAvatar, 
  Divider, 
  Box,
  MenuItem,
  TextField,
  Select
} from '@material-ui/core';
import { Edit, Face, Send } from '@material-ui/icons';
import { Header, SideBar, Breadcrumb } from "../../Partials";
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { updateMemberProfile } from "../../../Redux/actions/MemberActions";
import { fetchUserCellAttendance, fetchChurchAttendance } from "../../../Redux/actions/attendanceAction";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Helmet } from "react-helmet";
import moment from 'moment';
import MaterialTable from "material-table";

import ChurchAttendanceTable from './Table/ChurchAttendance'


const theme = createTheme({
  typography: {
      fontFamily: [
          '"Rubik"', // Set Rubik as the primary font
          'sans-serif',
      ].join(','),
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  profileCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    boxShadow: theme.shadows[5],
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
  },
  profileImage: {
    width: 120,
    height: 120,
    marginBottom: theme.spacing(2),
    border: `3px solid ${theme.palette.primary.main}`,
  },
  profileInfo: {
    marginBottom: theme.spacing(2),
  },
  tabContent: {
    padding: theme.spacing(3),
  },
  tabCard: {
    padding: theme.spacing(2),
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
  },

  div: {
    padding: theme.spacing(4),
  },

  sectionTitle: {
    marginTop: theme.spacing(3),
    fontWeight: 'bold',
  },
  sectionDivider: {
    margin: theme.spacing(2, 0),
  },
  tabContentItem: {
    marginBottom: theme.spacing(1),
  },

  m2: {
    marginTop: theme.spacing(2),
  },

  m2B: {
    marginBottom: theme.spacing(2),
  },
  label: {
    fontWeight: 'bold',
    fontSmooth: 'always',
    WebkitFontSmoothing: 'antialiased', 
    MozOsxFontSmoothing: 'grayscale',
},
value: {
    fontWeight: 'normal', 
    fontSmooth: 'always', 
    WebkitFontSmoothing: 'antialiased', 
    MozOsxFontSmoothing: 'grayscale', 
    color: '#6C757D'
},

inline: {
  display: 'inline',
},
box: {  
  height: '850px', 
  overflowY: 'scroll'
},

}));

const MemberList = () => {
  const classes = useStyles();
  const location = useLocation();
   const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const { user:userz } = location.state || {};
  const [editMode, setEditMode] = useState(false);
  const [editedUser, setEditedUser] = useState(userz);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const toggleEditMode = () => {
    setEditMode((prev) => !prev);
  };

  const attendance = useSelector((state) => state.Attendance);


  
  const user = {
   ...userz,
    activity: [
      'Call response to user on 2022-12-01',
      'Call response to user on 2022-12-03',
    ],
    classes: [
      { name: 'React Basics', startDate: '2023-01-15', endDate: '2023-01-25', status: 'Completed' },
      { name: 'Advanced React', startDate: '2023-02-01', endDate: '2023-02-15', status: 'Completed' },
      { name: 'JavaScript Fundamentals', startDate: '2023-02-20', endDate: '2023-04-01', status: 'Ongoing' },
    ],
    groups: ['React Enthusiasts', 'JavaScript Learners', 'Web Developers'],
  };

  useEffect(() => {
      dispatch(fetchUserCellAttendance(user.id));
      dispatch(fetchChurchAttendance(user.id));
  }, [dispatch,user.id !== undefined]);

  const img_url = user.gender === 'Male' ? 'assets/images/avatars/avatar1.png' : 'assets/images/avatars/avatar2.png';

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderCellTab = () => (
    <div className={classes.tabContent}>  
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <div>
                  <MaterialTable
                    title="Cell Attendance"
                    columns={[
                      { 
                        title: 'Date', 
                        field: 'date', 
                        render: rowData => {
                          return moment(rowData.date).format('MMM D, YYYY');
                        },
                      },
                      { 
                        title: 'Status', 
                        field: 'status', 
                        render: rowData => {
                          const statusColor = rowData.status === 'Attended' ? 'green' : 'red';
                          return <span style={{ backgroundColor: statusColor, color: '#fff', padding: 8, borderRadius: 10 }}>{rowData.status}</span>;
                        },
                        cellStyle: {
                          fontWeight: 'bold',
                        },
                      },
                     { title: "Reason", field: "reason" },
                    ]}
                    data={attendance.user_cell_records}
                    
              
                    options={{
                      sorting: true,
                    }}
                    
                  />
            </div>
          </Grid>
        </Grid>
    </div>
  );

  const renderChurchTab = () => (
    <div className={classes.tabContent}>  
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <div>
                  <MaterialTable
                    title="Church Attendance"
                    columns={[
                      { 
                        title: 'Date', 
                        field: 'date', 
                        render: rowData => {
                          return moment(rowData.date).format('MMM D, YYYY');
                        },
                      },
                      { 
                        title: 'Status', 
                        field: 'status', 
                        render: rowData => {
                          const statusColor = rowData.status === 'Attended' ? 'green' : 'red';
                          return <span style={{ backgroundColor: statusColor, color: '#fff', padding: 8, borderRadius: 10 }}>{rowData.status}</span>;
                        },
                        cellStyle: {
                          fontWeight: 'bold',
                        },
                      },
                     { title: "Reason", field: "reason" },
                    ]}
                    data={attendance.user_church_records}
                    
                    options={{
                      sorting: true,
                    }}
                    
                  />
            </div>
          </Grid>
        </Grid>
    </div>
  );

  const renderGroupsTab = () => (
    <div className={classes.tabContent}>
      {user.groups.map((group, index) => (
        <Typography key={index} variant="body2">
          {group}
        </Typography>
      ))}
    </div>
  );

  const saveUserToFirestore = () => { 
    dispatch(updateMemberProfile(editedUser, user.id, toggleEditMode))
   }


  return (
    <React.Fragment>
      <Helmet>
        <title>View Each Member</title>
      </Helmet>
      <Header />
      <SideBar />

      {/* CLOSE SIDE BAR */}
      <div className="page-content">
      <div className="container-fluid">
      <div className={classes.root}>
     
        <Grid container spacing={3}>
          <Grid item xs={12}> </Grid>
          <Grid item xs={12} sm={6}>
            <Card className={classes.div}>
              <Grid container spacing={2} >
                <Grid item xs={12} sm={4}>
                  <Avatar src={user.imageUrl !== undefined ? user.imageUrl : img_url} alt="Profile Picture" className={classes.profileImage} />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingTop: '20px' }}>
                  <Button variant="contained" size="large" color="primary" className={[classes.margin, classes.m2B]}  endIcon={<Send>send</Send>}>
                    send message
                  </Button>

                  <Button variant="outlined" size="large" color="primary" className={[classes.margin]} startIcon={<Face>face</Face>}>
                    Change Picture
                  </Button>
                </Grid>
              </Grid>
            </Card>

            <Card className="card" style={{ paddingLeft: '20px', paddingBottom: '20px', paddingRight: '20px' }}>
              <Grid container spacing={2}>
                {/* Left Grid */}
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography className={classes.label} variant="subtitle1">{`Name:`}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {editMode ? (
                            <TextField
                              name="name"
                              value={editedUser.name}
                              onChange={handleInputChange}
                              fullWidth
                              variant="outlined"
                            />
                          ) : (
                            <Typography variant="body1">{user.name}</Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography className={classes.label} variant="subtitle1">{`Email:`}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {editMode ? (
                            <TextField
                              name="email"
                              value={editedUser.email}
                              onChange={handleInputChange}
                              fullWidth
                              variant="outlined"
                              InputProps={{
                                readOnly: true, 
                              }}
                            />
                          ) : (
                            <Typography variant="body1">{user.email}</Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography className={classes.label} variant="subtitle1">{`Address:`}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {editMode ? (
                            <TextField
                              name="address"
                              value={editedUser.address}
                              onChange={handleInputChange}
                              fullWidth
                              variant="outlined"
                            />
                          ) : (
                            <Typography variant="body1">{user.address}</Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography className={classes.label} variant="subtitle1">{`Country:`}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {editMode ? (
                            <TextField
                              name="country"
                              value={editedUser.country}
                              onChange={handleInputChange}
                              fullWidth
                              variant="outlined"
                            />
                          ) : (
                            <Typography variant="body1">{user.country}</Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography className={classes.label} variant="subtitle1">{`State of Origin:`}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {editMode ? (
                            <TextField
                              name="state"
                              value={editedUser.state}
                              onChange={handleInputChange}
                              fullWidth
                              variant="outlined"
                            />
                          ) : (
                            <Typography variant="body1">{user.state}</Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography className={classes.label} variant="subtitle1">{`Membership Status:`}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {editMode ? (
                            <TextField
                              name="membership_status"
                              value={editedUser.membership_status}
                              onChange={handleInputChange}
                              fullWidth
                              variant="outlined"
                            />
                          ) : (
                            <Typography variant="body1">{user.membership_status || '-'}</Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography className={classes.label} variant="subtitle1">{`Occupation:`}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {editMode ? (
                            <TextField
                              name="occupation"
                              value={editedUser.occupation}
                              onChange={handleInputChange}
                              fullWidth
                              variant="outlined"
                            />
                          ) : (
                            <Typography variant="body1">{user.occupation || '-'}</Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography className={classes.label} variant="subtitle1">{`Cell Leader:`}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {editMode ? (
                            <TextField
                              name="cell_leader"
                              value={editedUser.cell_leader}
                              onChange={handleInputChange}
                              fullWidth
                              variant="outlined"
                            />
                          ) : (
                            <Typography variant="body1">{user.cell_leader || '-'}</Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>

                {/* Right Grid */}
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography className={classes.label} variant="subtitle1">{`Phone Number:`}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {editMode ? (
                            <TextField
                              name="phone_number"
                              value={editedUser.phone_number}
                              onChange={handleInputChange}
                              fullWidth
                              variant="outlined"
                            />
                          ) : (
                            <Typography variant="body1">{user.phone_number}</Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography className={classes.label} variant="subtitle1">{`WhatsApp Number:`}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {editMode ? (
                            <TextField
                              name="whatsappNumber"
                              value={editedUser.whatsappNumber}
                              onChange={handleInputChange}
                              fullWidth
                              variant="outlined"
                            />
                          ) : (
                            <Typography variant="body1">{user.whatsappNumber || '-'}</Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography className={classes.label} variant="subtitle1">{`Date of Birth:`}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {editMode ? (
                            <TextField
                              name="date_of_birth"
                              value={editedUser.date_of_birth}
                              onChange={handleInputChange}
                              fullWidth
                              variant="outlined"
                            />
                          ) : (
                            <Typography variant="body1">{user.date_of_birth || '-'}</Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography className={classes.label} variant="subtitle1">{`Marital Status:`}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {editMode ? (
                            <TextField
                              name="marital_status"
                              value={editedUser.marital_status}
                              onChange={handleInputChange}
                              fullWidth
                              variant="outlined"
                            />
                          ) : (
                            <Typography variant="body1">{user.marital_status || '-'}</Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* Additional fields for right grid */}
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography className={classes.label} variant="subtitle1">{`Local Government Area:`}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {editMode ? (
                            <TextField
                              name="lga"
                              value={editedUser.lga}
                              onChange={handleInputChange}
                              fullWidth
                              variant="outlined"
                            />
                          ) : (
                            <Typography variant="body1">{user.lga}</Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>


                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography className={classes.label} variant="subtitle1">{`MVP Assigned:`}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {editMode ? (
                            <TextField
                              name="mvp_assigned"
                              value={editedUser.mvp_assigned}
                              onChange={handleInputChange}
                              fullWidth
                              variant="outlined"
                            />
                          ) : (
                            <Typography variant="body1">{user.mvp_assigned || '-'}</Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography className={classes.label} variant="subtitle1">{`Branch:`}</Typography>
                        </Grid>

                        <Grid item xs={12}>
                          {editMode ? (
                            <Select
                              name="branch"
                              margin="normal"
                              fullWidth
                              variant="filled"
                              value={editedUser.branch}
                              onChange={handleInputChange}
                              
                            >
                              <MenuItem value=""></MenuItem>
                              <MenuItem value={"Ikeja"}>Ikeja</MenuItem>
                              <MenuItem value={"Ibeju Lekki"}> Ibeju Lekki</MenuItem>
                              <MenuItem value={"Sangotedo"}> Sangotedo</MenuItem>
                              <MenuItem value={"Port Harcourt"}>Port Harcourt</MenuItem>
                              <MenuItem value={"Aba"}>Aba</MenuItem>
                              <MenuItem value={"Umuahia"}>Umuahia</MenuItem>
                            </Select>
                            ) : (
                            <Typography variant="body1">{user.branch || '-'}</Typography>
                          )}
                            </Grid>
                      </Grid>
                    </Grid>


                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography className={classes.label} variant="subtitle1">{`Date Joined:`}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {editMode ? (
                            <TextField
                              name="date_joined"
                              value={editedUser.date_joined}
                              onChange={handleInputChange}
                              fullWidth
                              variant="outlined"
                            />
                          ) : (
                            <Typography variant="body1">{user.date_joined || '-'}</Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>

                {/* Edit/Save Button */}
                <Grid item xs={12} className="m2">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Edit />}
                    onClick={editMode ? saveUserToFirestore : toggleEditMode}
                  >
                    {editMode ? 'Save' : 'Edit'}
                  </Button>
                </Grid>
              </Grid>
            </Card>

              {/* rendering of a persons attendance and schools */}
            <Card className={[ classes.m2]}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Tabs value={activeTab} onChange={handleTabChange}  indicatorColor="primary" textColor="primary"> 
                      <Tab label="Cell Attendance" />
                      <Tab label="Church Attendance" />
                      <Tab label="Schools Attended" />
                    </Tabs>

                    {activeTab === 0 && renderCellTab()}
                    {activeTab === 1 && renderChurchTab()}
                    {activeTab === 2 && renderGroupsTab()}
                  </CardContent>
                </Card>
              </Grid>
            </Card>
          </Grid>

           




          {/* report grid */}
          <Grid item xs={12} sm={6}>
              <Box sx={classes.box}>
                <Card>
                    <List>
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                          </ListItemAvatar>
                          <ListItemText
                            primary="Jan, 1st, 2025"
                            style={{ color: '#6C757D'}}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  className={classes.inline}
                                  color="textPrimary"
                                >
                                  Ali Connors
                                </Typography>
                                {" — I'll be in your neighborhood doing errands this, Do you have Paris recommendations? Have you ever, Do you have Paris recommendations? Have you ever"}
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      <Divider variant="inset" component="li" />
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                          </ListItemAvatar>
                          <ListItemText
                            primary="Jan, 8th, 2025"
                            style={{ color: '#6C757D'}}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  className={classes.inline}
                                  color="textPrimary"
                                >
                                  to Scott, Alex, Jennifer
                                </Typography>
                                {" — Wish I could come, but I'm out of town this, Do you have Paris recommendations? Have you ever, Do you have Paris recommendations? Have you ever"}
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      <Divider variant="inset" component="li" />
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
                          </ListItemAvatar>
                          <ListItemText
                            primary="Jan, 15th, 2025"
                            style={{ color: '#6C757D'}}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  className={classes.inline}
                                  color="textPrimary"
                                >
                                  Sandra Adams
                                </Typography>
                                {' — Do you have Paris recommendations?'}
                              </React.Fragment>
                            }
                          />
                        </ListItem>

                    </List>
                </Card>
              </Box>
          </Grid>
         
        
        </Grid>
    </div>
    </div>
    </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default MemberList;
