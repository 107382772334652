export const Role = {
  SA: 'SA',
  ADMIN: 'ADMIN',
  CL: 'CL', // CELL_LEADER
  DL: 'DL',
  SEC: 'SEC',
  MEDIA: 'MEDIA',

  
  FIRST_TIMERS: 'FIRST_TIMERS',
  SECOND_TIMERS: 'SECOND_TIMERS',
  FULL_MEMBER: 'FULL_MEMBER',
};

/*
SA :super admin
MVP: most important person
SEC:secretary
CL:house fellowship leadership

 */
